import React from 'react';
import Menu from './Menu';

import './UpdateOrder.scss';

export default function UpdateOrder(props) {
    const { orderActive, setreloadPage, setIsModalVisible } = props;
    return (
        <div>
            <Menu
                orderActive={orderActive}
                setreloadPage={setreloadPage}
                setIsModalVisible={setIsModalVisible}
            />
        </div>
    );
}

// function Menu(props) {
//     const { orderActive } = props;
//     const { companyId, orderType, orderId } = orderActive;
//     const isMounted = useRef(true);
//     const { TabPane } = Tabs;
//     const [group, setGroup] = useState();
//     const [loading, setLoading] = useState(true);
//     const [loadingItems, setLoadingItems] = useState(false);
//     const { Title } = Typography;
//     const token = getAccessTokenApi();
//     // const [counterItem, setCounterItem] = useState(0);
//     const [groupSearches, setGroupSearches] = useState([]);
//     const [activeKey, setActiveKey] = useState('');
//     const [isModalVisible, setIsModalVisible] = useState(false);
//     const [contextModal, setContextModal] = useState(null);
//     const [titleModal, setTitleModal] = useState('');
//     const [orderActiveUpdate, setOrderActiveUpdate] = useState({
//         counterItem: 0,
//         subtotal: 0,
//         details: [],
//     });
//     // const [subtotal, setSubtotal] = useState(0);

//     useEffect(() => {
//         return () => {
//             isMounted.current = false;
//         };
//     }, []);

//     useEffect(() => {
//         const data = {
//             CompanyId: companyId,
//             OrderType: orderType,
//             MenuClient: true,
//         };

//         getCompleteMenuClient(token, data).then((group) => {
//             console.log(group, 'group');
//             if (isMounted.current) {
//                 if (group !== undefined) {
//                     if (group.length > 0) {
//                         let idGroupOne = group[0]['groupId'];

//                         const dataApi = {
//                             CompanyId: companyId,
//                             GroupId: idGroupOne,
//                             OrderType: orderType,
//                             MenuClient: true,
//                         };
//                         getMenuGroupApi(token, dataApi).then((resp) => {
//                             group[0] = resp;
//                             setActiveKey(idGroupOne.toString());
//                             setGroup(group);
//                             setLoading(false);
//                             setGroupSearches([idGroupOne]);
//                         });
//                     } else {
//                         setGroup([]);
//                         setLoading(false);
//                     }
//                 } else {
//                     notification['error']({
//                         message:
//                             'Verifique su conexión a internet, si no se resuelve intente más tarde.',
//                     });
//                 }
//             }
//         });

//         return () => {
//             setGroup([]);
//         };
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [companyId, orderType]);

//     const callbackGroup = (key) => {
//         let idGroup = parseInt(key);
//         const foundIdGroup = groupSearches.find((id) => id === idGroup);
//         if (foundIdGroup === undefined) {
//             setLoadingItems(true);
//             let dataApi = {
//                 CompanyId: companyId,
//                 GroupId: idGroup,
//                 OrderType: orderType,
//                 MenuClient: true,
//             };
//             getMenuGroupApi(token, dataApi).then((resp) => {
//                 if (isMounted.current) {
//                     if (resp !== undefined) {
//                         setGroupSearches([...groupSearches, idGroup]);
//                         const found = group.findIndex(
//                             (item) => item.groupId === resp.groupId
//                         );
//                         group[found] = resp;
//                         setGroup(group);
//                         setActiveKey(idGroup.toString());
//                         setLoadingItems(false);
//                     }

//                     if (resp === undefined) {
//                         message.error(
//                             'Verifique su conexión a internet, si no se resuelve intente más tarde.'
//                         );
//                         setActiveKey(idGroup.toString());
//                         setLoadingItems(false);
//                     }
//                 } else {
//                     console.log('NO HARAS NADA');
//                 }
//             });
//         } else {
//             setActiveKey(key);
//         }
//     };

//     const showModal = (item, title) => {
//         console.log(title);
//         if (title === 'Order') {
//             //MOSTAR CARRITO
//             setContextModal(
//                 <ShoppingCart
//                     orderActive={orderActiveUpdate}
//                     setIsModalVisible={setIsModalVisible}
//                     setOrderActiveUpdate={setOrderActiveUpdate}
//                     orderId={orderId}
//                 />
//             );
//             setTitleModal('Orden');
//             setIsModalVisible(true);
//         } else {
//             setContextModal(
//                 <DescriptionItem
//                     item={item}
//                     setIsModalVisible={setIsModalVisible}
//                     updateOrder
//                     orderActive={orderActiveUpdate}
//                     setOrderActiveUpdate={setOrderActiveUpdate}
//                 />
//             );
//             setTitleModal(title);
//             setIsModalVisible(true);
//         }
//     };

//     const listData = [];
//     for (let i = 0; i < 5; i++) {
//         listData.push({
//             href: 'https://ant.design',
//             title: `menuClient ${i}`,
//             avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
//             description:
//                 'Ant Design, a design language for background applications, is refined by Ant UED Team.',
//             content:
//                 'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
//         });
//     }

//     return (
//         <>
//             {loading === false && (
//                 <div className="update-order">
//                     <Spin spinning={loadingItems} tip="Cargando...">
//                         <Tabs
//                             activeKey={activeKey}
//                             defaultActiveKey={activeKey}
//                             centered
//                             className={
//                                 'menuCustomer ' +
//                                 (orderActiveUpdate.counterItem > 0
//                                     ? 'active-scroll-modal'
//                                     : '')
//                             }
//                             onChange={callbackGroup}
//                         >
//                             {group.length > 0 &&
//                                 group.map((group) => {
//                                     return (
//                                         <Fragment key={'group' + group.groupId}>
//                                             {group.categories.length > 0 && (
//                                                 <TabPane
//                                                     tab={group.name}
//                                                     key={group.groupId}
//                                                 >
//                                                     {group.categories.length >
//                                                         0 &&
//                                                         group.categories.map(
//                                                             (cat) => {
//                                                                 return (
//                                                                     <Fragment
//                                                                         key={
//                                                                             cat.categoryId
//                                                                         }
//                                                                     >
//                                                                         {cat
//                                                                             .items
//                                                                             .length >
//                                                                             0 && (
//                                                                             <Divider
//                                                                                 orientation="left"
//                                                                                 plain
//                                                                             >
//                                                                                 <Title
//                                                                                     level={
//                                                                                         4
//                                                                                     }
//                                                                                 >
//                                                                                     {
//                                                                                         cat.name
//                                                                                     }
//                                                                                 </Title>
//                                                                             </Divider>
//                                                                         )}

//                                                                         {cat
//                                                                             .items
//                                                                             .length >
//                                                                             0 &&
//                                                                             cat.items.map(
//                                                                                 (
//                                                                                     item
//                                                                                 ) => {
//                                                                                     return (
//                                                                                         <Card
//                                                                                             className="card-dish"
//                                                                                             key={
//                                                                                                 item.itemId
//                                                                                             }
//                                                                                         >
//                                                                                             <Row>
//                                                                                                 <Col
//                                                                                                     span={
//                                                                                                         14
//                                                                                                     }
//                                                                                                 >
//                                                                                                     <Title
//                                                                                                         level={
//                                                                                                             4
//                                                                                                         }
//                                                                                                     >
//                                                                                                         {
//                                                                                                             item.name
//                                                                                                         }
//                                                                                                     </Title>
//                                                                                                     {!item.available && (
//                                                                                                         <p className="card-dish__noAvailable">
//                                                                                                             No
//                                                                                                             está
//                                                                                                             disponible
//                                                                                                         </p>
//                                                                                                     )}
//                                                                                                     <p className="card-dish__description">
//                                                                                                         {
//                                                                                                             item.description
//                                                                                                         }
//                                                                                                     </p>
//                                                                                                     <p>
//                                                                                                         {' '}
//                                                                                                         $
//                                                                                                         {formatMoney(
//                                                                                                             item.price
//                                                                                                         )}
//                                                                                                     </p>
//                                                                                                     <Button
//                                                                                                         icon={
//                                                                                                             <PlusOutlined />
//                                                                                                         }
//                                                                                                         danger
//                                                                                                         onClick={() =>
//                                                                                                             showModal(
//                                                                                                                 item,
//                                                                                                                 ''
//                                                                                                             )
//                                                                                                         }
//                                                                                                         disabled={
//                                                                                                             item.available
//                                                                                                                 ? false
//                                                                                                                 : true
//                                                                                                         }
//                                                                                                     >
//                                                                                                         Agregar
//                                                                                                     </Button>
//                                                                                                 </Col>
//                                                                                                 <Col
//                                                                                                     span={
//                                                                                                         10
//                                                                                                     }
//                                                                                                     className="text-right"
//                                                                                                 >
//                                                                                                     {item.itemImage !==
//                                                                                                         null && (
//                                                                                                         <Image
//                                                                                                             width={
//                                                                                                                 120
//                                                                                                             }
//                                                                                                             src={
//                                                                                                                 item.itemImage !==
//                                                                                                                 null
//                                                                                                                     ? `data:image/${item.itemImage.extension};base64, ${item.itemImage.imageData}`
//                                                                                                                     : food
//                                                                                                             }
//                                                                                                         />
//                                                                                                     )}
//                                                                                                 </Col>
//                                                                                             </Row>
//                                                                                         </Card>
//                                                                                     );
//                                                                                 }
//                                                                             )}
//                                                                         {/* </Spin> */}
//                                                                     </Fragment>
//                                                                 );
//                                                             }
//                                                         )}
//                                                 </TabPane>
//                                             )}
//                                         </Fragment>
//                                     );
//                                 })}
//                         </Tabs>
//                         {group.length === 0 && (
//                             <Empty description={'No hay productos'} />
//                         )}
//                     </Spin>
//                     {orderActiveUpdate.counterItem > 0 && (
//                         <div className="menu-costumer-button">
//                             <Button
//                                 type="primary"
//                                 block
//                                 danger
//                                 size="large"
//                                 onClick={() => showModal(undefined, 'Order')}
//                             >
//                                 <Row>
//                                     <Col
//                                         span={4}
//                                         className="menu-costumer-button__left"
//                                     >
//                                         <div className="counter-square">
//                                             {orderActiveUpdate.counterItem}
//                                         </div>
//                                     </Col>

//                                     <Col
//                                         span={16}
//                                         className="menu-costumer-button__center"
//                                     >
//                                         Ver Canasta
//                                     </Col>

//                                     {/* <Col
//                                         span={10}
//                                         className="menu-costumer-button__right"
//                                     >
//                                         <p>
//                                             Total $
//                                             {formatMoney(
//                                                 orderActiveUpdate.subtotal
//                                             )}
//                                         </p>
//                                     </Col> */}
//                                 </Row>
//                             </Button>
//                         </div>
//                     )}
//                 </div>
//             )}

//             {loading === true && (
//                 <List
//                     itemLayout="vertical"
//                     size="large"
//                     dataSource={listData}
//                     renderItem={(item) => (
//                         <List.Item key={item.title}>
//                             <Skeleton loading={loading} active avatar>
//                                 <List.Item.Meta
//                                     avatar={<Avatar src={item.avatar} />}
//                                     title={<a href={item.href}>{item.title}</a>}
//                                     description={item.description}
//                                 />
//                                 {item.content}
//                             </Skeleton>
//                         </List.Item>
//                     )}
//                 />
//             )}

//             <Modal
//                 isVisible={isModalVisible}
//                 setIsVisible={setIsModalVisible}
//                 title={titleModal}
//                 footer={false}
//                 destroyOnClose
//             >
//                 {contextModal}
//             </Modal>
//         </>
//     );
// }

// function ShoppingCart(props) {
//     const { orderActive, setIsModalVisible, setOrderActiveUpdate, orderId } =
//         props;
//     const { subtotal, details } = orderActive;
//     const [reloadOrder, setReloadOrder] = useState(false);
//     const [total, setTotal] = useState(subtotal);
//     const [products, setProducts] = useState(details);
//     const [paypalType, setPaypalType] = useState(1);
//     const [confirmOrder, setConfirmOrder] = useState(true);
//     const [stripePaymentMethod, setStripePaymentMethod] = useState('');
//     const [messageActiveSpin, setMessageActiveSpin] = useState(
//         'Enviando la orden...'
//     );
//     const [activeSpin, setActiveSpin] = useState(false);
//     const [acceptCardCredit, setAcceptCardCredit] = useState(true);

//     const { company, setCompany } = useCompany();
//     console.log(company);

//     const { Title, Text, Paragraph } = Typography;
//     const { Panel } = Collapse;

//     useEffect(() => {
//         let suma = 0;

//         details.forEach((element) => {
//             if (element.typeUoms !== undefined) {
//                 suma =
//                     suma +
//                     (element.extraPrice + parseFloat(element.priceUoms)) *
//                         element.quantity;
//             } else {
//                 suma =
//                     suma +
//                     (element.extraPrice + element.price) * element.quantity;
//             }
//         });

//         setTotal(suma);
//         setReloadOrder(false);
//         // setCounterItem(orderGeneral.orderLinesList.length);

//         setOrderActiveUpdate({
//             ...orderActive,
//             subtotal: suma,
//             details: details,
//             counterItem: details.length,
//         });

//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [reloadOrder]);

//     useEffect(() => {
//         console.log(company);
//         if (company === null) {
//             setConfirmOrder(false);
//             const token = getAccessTokenApi();
//             const idComany = getCompanyIdApi();
//             getCompanyApi(token, idComany)
//                 .then((response) => {
//                     console.log(response);

//                     if (response?.statusCode === 401) {
//                         notification['error']({
//                             message: 'Usuario no autorizado',
//                         });

//                         setTimeout(() => {
//                             logout();
//                             window.location.reload();
//                         }, 1500);
//                         return;
//                     }
//                     if (response.error) {
//                         return false;
//                     }
//                     setCompany({
//                         ...company,
//                         Location: response.address,
//                         State: response.state,
//                         Neighborhood: response.neighborhood,
//                         ZipCode: response.zipCode,
//                         City: response.city,
//                         Telephone: response.phone,
//                         CompanyImage: response.companyImage,
//                         DeliveryCostKM: response.deliveryCostKM,
//                         DeliveryCost: response.deliveryCost,
//                         DeliveryExtraKmCost: response.deliveryExtraKmCost,
//                         DeliverryMaxDistance: response.deliverryMaxDistance,
//                         ServiceMaxDistance: response.serviceMaxDistance,
//                         AllowMultipleActiveOrders:
//                             response.allowMultipleActiveOrders,
//                         Name: response.name,
//                         PickUp: response.pickUp,
//                         ToTable: response.toTable,
//                         Delivery: response.delivery,
//                         Latitude: response.latitude,
//                         Longitude: response.longitude,
//                         CreditCard: response.creditCard,
//                         CashOnly: response.cashOnly,
//                         OrderMinimumPurchase: response.orderMinimumPurchase,
//                     });
//                     setConfirmOrder(true);
//                 })
//                 .catch((err) => {
//                     console.log(err);
//                 });
//         }
//         // eslint-disable-next-line react-hooks/exhaustive-deps
//     }, [company]);

//     function confirm(itemId) {
//         const found = details.findIndex((item) => item.itemId === itemId);
//         details.splice(found, 1);
//         setOrderActiveUpdate({ ...orderActive, details: details });
//         setProducts(details);
//         setReloadOrder(true);
//         message.success('Eliminado exitosamente');
//     }

//     function cancel(e) {}

//     function incrementItem(dish) {
//         const found = details.findIndex((item) => item.itemId === dish.itemId);
//         details[found].quantity = dish.quantity + 1;
//         setProducts(details);
//         // setOrderState(orderState);
//         setReloadOrder(true);
//     }

//     function decrementItem(dish) {
//         const found = details.findIndex((item) => item.itemId === dish.itemId);
//         details[found].quantity = dish.quantity - 1;
//         setProducts(details);
//         setReloadOrder(true);
//     }

//     const onChangePayment = (e) => {
//         setPaypalType(e.target.value);
//         // setOrderGeneral({ ...orderGeneral, PayType: e.target.value });
//         if (e.target.value === 2) {
//             setConfirmOrder(false);
//             setMessageActiveSpin('Cargando...');
//         } else {
//             setConfirmOrder(true);
//         }
//     };

//     const createOrder = async () => {
//         let updateOrder = {
//             orderId: orderId,
//             orderLinesList: details,
//         };

//         if (company.OrderMinimumPurchase) {
//             if (subtotal < company.OrderMinimumPurchase) {
//                 notification['error']({
//                     message: `Pedido mínimo de $ ${formatMoney(
//                         company.OrderMinimumPurchase
//                     )} `,
//                 });

//                 return;
//             }
//         }

//         // if (!validationDate.valid) {
//         //     notification['error']({
//         //         message: validationDate.message,
//         //     });
//         //     return;
//         // }
//         // if (dateDelivery.day() === 0) {
//         //     notification['error']({
//         //         message:
//         //             'La fecha de entrega no puede ser los dias DOMINGO, por favor seleccione otra fecha.',
//         //     });

//         //     return;
//         //     // console.log(moment().day());
//         // }

//         setMessageActiveSpin('Enviando la orden...');
//         setActiveSpin(true);

//         //DATE DELIVERY
//         // orderState['EstimatedDeliveryDate'] = dateDelivery.unix();

//         //payment intent id
//         updateOrder.StripePaymentMethod = stripePaymentMethod;

//         let orderSend = updateOrder;

//         if (orderSend.orderLinesList && orderSend.orderLinesList.length > 0) {
//             orderSend.orderLinesList.forEach((orderLine) => {
//                 orderLine.measurementUnits = parseFloat(
//                     orderLine.measurementUnits
//                 );
//                 orderLine.selectedOptionIds = [];
//                 if (orderLine.options) {
//                     orderLine.options.forEach((opt) => {
//                         orderLine.selectedOptionIds = [
//                             ...orderLine.selectedOptionIds,
//                             opt.sectionOptionId,
//                         ];
//                     });
//                 }
//                 orderLine.itemId = parseInt(orderLine.itemId);
//             });
//         }

//         // console.log('Orden a enviar', orderSend);
//         const token = getAccessTokenApi();
//         const result = undefined;
//         // const result = await UpdateOrderApi(token, orderSend);
//         if (result !== undefined) {
//             if (result.statusCode === 200) {
//                 notification['success']({
//                     message: 'Orden creada exitosamente',
//                 });
//                 setActiveSpin(false);
//                 // history.push(`/statusOrder/${result.result.orderId}`);
//             } else {
//                 let message = 'Ocurio un error, intentelo de nuevo';

//                 if (result.statusCode === 404) {
//                     message = result.description;

//                     setActiveSpin(false);
//                     notification['error']({
//                         message: message,
//                     });
//                 } else if (result.statusCode === 405) {
//                     setActiveSpin(false);
//                     notification['error']({
//                         message: result.description,
//                     });
//                     // history.push('/disable/restaurant');
//                 } else if (result.statusCode === 401) {
//                     setActiveSpin(false);
//                     notification['error']({
//                         message: result.description,
//                     });
//                     logout();
//                     setTimeout(() => {
//                         window.location.href = '/login';
//                     }, 1000);
//                 } else {
//                     setActiveSpin(false);
//                     notification['error']({
//                         message: message,
//                     });
//                 }
//             }
//         } else {
//             let message = 'Ocurio un error, intentelo de mas tarde';
//             setActiveSpin(false);
//             setIsModalVisible(false);
//             notification['error']({
//                 message: message,
//             });
//         }
//     };

//     return (
//         <div>
//             <Paragraph>Orden actualizada el: {moment().format('L')}</Paragraph>
//             <Divider>Tu Orden</Divider>

//             {details.length > 0 && (
//                 <div className="basket-form__container-add">
//                     <Button
//                         size="small"
//                         type="primary"
//                         onClick={() => setIsModalVisible(false)}
//                     >
//                         Agregar Producto
//                     </Button>
//                 </div>
//             )}

//             <div
//                 className={
//                     details.length > 3
//                         ? 'basket-form__container-groupsItem'
//                         : ''
//                 }
//             >
//                 {products.map((item) => {
//                     return (
//                         <Row
//                             className="basket-form__container-items"
//                             key={item.itemIdUnique}
//                             // key={item.itemId}
//                         >
//                             <Col span={14}>
//                                 <Title level={4}>{item.name}</Title>
//                                 <Row>
//                                     <p className="gray">
//                                         $
//                                         {formatMoney(
//                                             item.priceUoms
//                                                 ? parseFloat(item.priceUoms)
//                                                 : item.price
//                                         )}
//                                     </p>
//                                     {item.measurementUnits !== 0 &&
//                                         item.measurementUnits !== undefined && (
//                                             <p className="gray">
//                                                 -({item.measurementUnits})
//                                                 {item.typeUoms}
//                                             </p>
//                                         )}
//                                 </Row>

//                                 {item.instructions !== '' && (
//                                     <Collapse ghost>
//                                         <Panel header="Instrucciones" key="1">
//                                             <p>{item.instructions}</p>
//                                         </Panel>
//                                     </Collapse>
//                                 )}
//                                 <div className="basket-form__container-items">
//                                     {item.options.map((option) => (
//                                         <Row key={option.sectionOptionId}>
//                                             <Col span={24}>
//                                                 <Text className="section-items">
//                                                     <Text
//                                                         strong
//                                                     >{`${option.nameSection}: `}</Text>
//                                                     <Text>{`${option.name}`}</Text>

//                                                     {option.price && (
//                                                         <Text className="gray margin-left">
//                                                             $
//                                                             {formatMoney(
//                                                                 option.price
//                                                             )}
//                                                         </Text>
//                                                     )}
//                                                 </Text>
//                                             </Col>
//                                         </Row>
//                                     ))}
//                                 </div>
//                             </Col>
//                             <Col span={10}>
//                                 <Row className="counter">
//                                     <Col span={8}>
//                                         <Button
//                                             disabled={item.quantity === 1}
//                                             onClick={() => decrementItem(item)}
//                                             danger
//                                         >
//                                             -
//                                         </Button>
//                                     </Col>
//                                     <Col span={8}>{item.quantity}</Col>
//                                     <Col span={8}>
//                                         <Button
//                                             onClick={() => incrementItem(item)}
//                                             danger
//                                         >
//                                             +
//                                         </Button>
//                                     </Col>
//                                     <Col span={24} className="right">
//                                         <Popconfirm
//                                             title={`¿Estás seguro de eliminar el producto: ${item.name}?`}
//                                             onConfirm={() =>
//                                                 confirm(item.itemId)
//                                             }
//                                             onCancel={cancel}
//                                             okText="Si"
//                                             cancelText="No"
//                                         >
//                                             <Button icon={<DeleteOutlined />} />
//                                         </Popconfirm>
//                                     </Col>
//                                 </Row>
//                             </Col>
//                         </Row>
//                     );
//                 })}
//             </div>

//             {details.length === 0 && (
//                 <Empty
//                     className="empty-basket"
//                     image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
//                     imageStyle={{
//                         height: 60,
//                     }}
//                     description={<span>No hay ningun producto</span>}
//                 >
//                     <Button
//                         size="small"
//                         type="primary"
//                         onClick={() => setIsModalVisible(false)}
//                     >
//                         Agregar
//                     </Button>
//                 </Empty>
//             )}

//             <Row className="basket-form__container">
//                 <Col span={14} className="basket-form__container-left">
//                     <Paragraph>Total de la orden</Paragraph>
//                 </Col>
//                 <Col span={10} className="basket-form__container-right">
//                     <Paragraph>${formatMoney(total)}</Paragraph>
//                 </Col>
//             </Row>

//             <Divider>Método de Pago</Divider>

//             <div className="basket-form__container-paypal">
//                 <Radio.Group
//                     defaultValue={1}
//                     size="large"
//                     onChange={onChangePayment}
//                     value={paypalType}
//                 >
//                     <Radio.Button
//                         value={1}
//                         disabled={company?.CashOnly ? false : true}
//                     >
//                         {' '}
//                         <DollarOutlined /> Efectivo
//                     </Radio.Button>
//                     <Radio.Button
//                         value={2}
//                         disabled={
//                             company?.CreditCard && acceptCardCredit
//                                 ? false
//                                 : true
//                         }
//                     >
//                         {' '}
//                         <CreditCardOutlined /> Tarjeta T/C
//                     </Radio.Button>
//                 </Radio.Group>

//                 {paypalType === 2 && company?.CreditCard && (
//                     <Row className="change">
//                         <Elements stripe={promise}>
//                             <CheckoutForm
//                                 setConfirmOrder={setConfirmOrder}
//                                 setStripePaymentMethod={setStripePaymentMethod}
//                                 setActiveSpin={setActiveSpin}
//                                 setAcceptCardCredit={setAcceptCardCredit}
//                             />
//                         </Elements>
//                     </Row>
//                 )}
//             </div>

//             <Button
//                 disabled={products.length === 0 || confirmOrder === false}
//                 type="primary"
//                 danger
//                 block
//                 onClick={createOrder}
//             >
//                 Confirmar Pedido
//             </Button>
//         </div>
//     );
// }
