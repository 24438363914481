/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import {
    Typography,
    Divider,
    Button,
    Input,
    Row,
    Col,
    InputNumber,
} from 'antd';
import moment from 'moment';
import { TagFilled } from '@ant-design/icons';

import { useCouter } from '../../../hooks/useCouter';
import { formatMoney } from '../../../utils/general';
import { getCompanyIdApi } from '../../../api/company';
import userOrder from '../../../hooks/useOrder';
import Secciones from './Secciones';
import ImageCarousel from '../MenuCustomer/ImageCarousel/ImageCarousel';

import './DescriptionItem.scss';

const DescriptionItem = (props) => {
    const {
        item,
        setIsModalVisible,
        updateOrder,
        orderActive,
        setOrderActiveUpdate,
        setIsModalVisibleCart,
    } = props;
    // const { item, setIsModalVisible, setCounterItem, setTotalOrder, totalOrder } = props;
    const { name, description, itemId, itemImage, itemSections } = item;
    const { Title, Paragraph } = Typography;
    const { TextArea } = Input;

    let { state, decrement, increment, setState } = useCouter(1);
    const [total, setTotal] = useState(0);
    const [instructions, setInstructions] = useState('');
    const [typeQuantity, setTypeQuantity] = useState(0);
    const [priceQuantity, setPriceQuantity] = useState(
        item.unitOfMeasure ? item.unitOfMeasure.minimumPurchase : 0
    );
    const [totalFinal, setTotalFinal] = useState(0);
    // const [totalSinCupon, setTotalSinCupon] = useState(0);
    const [price, setPrice] = useState(item.price);
    const now = moment();

    // const [valueCheck, setValueCheck] = useState(2);
    let quantityMax;
    let quantityMin;
    let priceMax;
    // let priceMin;
    // const onChangeCheck = (e) => {
    //     setValueCheck(e.target.value);
    // };

    const companyId = getCompanyIdApi();
    const { orderGeneral, setOrderGeneral } = userOrder();
    const [extraPrice, setExtraPrice] = useState(0.0);
    const [optionTable, setOptionTable] = useState([]);
    const { Subtotal } = orderGeneral;

    const filtradas = itemSections.filter((ele) => ele.available);
    let nameUnitOfMeasure;
    if (item.unitOfMeasure !== null) {
        switch (item.unitOfMeasure.unitType) {
            case 0:
                nameUnitOfMeasure = '';
                break;
            case 1:
                nameUnitOfMeasure = 'Kg';
                break;
            case 2:
                nameUnitOfMeasure = 'gr';
                break;
            case 3:
                nameUnitOfMeasure = 'Lt';
                break;
            case 4:
                nameUnitOfMeasure = 'ml';
                break;
            default:
                break;
        }
    }

    // if(item.companyCoupon !== null && item.companyCoupon.type === 3 ){
    //     const newPricePorcent = item.price - item.companyCoupon.discountPrice;

    //                 item.price = newPricePorcent;
    // }
    // if(item.companyCoupon !== null && item.companyCoupon.type === 3 ){
    //     setPrice(price - item.companyCoupon.discountPrice)
    // }

    useEffect(() => {
        // console.log(item);

        const found = orderGeneral.orderLinesList.find(
            (element) => element.itemId === itemId
        );
        if (found !== undefined && found !== null) {
            setInstructions(found.instructions);
            if (item.itemSections.length > 0 || item.unitOfMeasure) {
                setState(1);
            } else {
                setState(found.quantity);
            }
        } else {
            setInstructions('');
        }
        setTotal(0);
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // let cantidadGuardada = 0;
    // console.log(item, "item")

    useEffect(() => {
        if (updateOrder) {
            if (item.unitOfMeasure !== null) {
                setTotalFinal(
                    total + (parseFloat(priceQuantity) + extraPrice) * state
                );
            } else {
                setTotalFinal(total + (extraPrice + price) * state);
            }
        } else {
            let originalPrice = item.price;
            let productosgratis = 0;
            let restarCupon = 0;
            // let quantitySinPromo = 0;
            // let contador = 0;
            let newPrice = 0;
            let newPricePorcent = 0;
            if (item.companyCoupon !== null) {
                switch (item.companyCoupon.type) {
                    case 1:
                        // if ((state / 2) <= item.companyCoupon.maxPromos || item.companyCoupon.maxPromos === 0) {
                        productosgratis = Math.trunc(state / 2);
                        if (item.unitOfMeasure !== null) {
                            restarCupon = productosgratis * priceQuantity;
                        } else {
                            restarCupon = productosgratis * price;
                        }
                        // }
                        // else {
                        //     restarCupon = 0;

                        //     quantitySinPromo = state - (item.companyCoupon.maxPromos * 2);
                        // }
                        break;
                    case 2:
                        // if ((state / 3) <= item.companyCoupon.maxPromos || item.companyCoupon.maxPromos === 0) {
                        productosgratis = Math.trunc(state / 3);
                        if (item.unitOfMeasure !== null) {
                            restarCupon = productosgratis * priceQuantity;
                        } else {
                            restarCupon = productosgratis * price;
                        }
                        // } else {
                        //     restarCupon = 0;
                        //     quantitySinPromo = state - (item.companyCoupon.maxPromos * 3);
                        //     console.log(quantitySinPromo, "quantitySinPromo")
                        // }

                        break;
                    case 3:
                        newPrice =
                            originalPrice - item.companyCoupon.discountPrice;
                        // console.log(newPrice, "newPrice")
                        if (newPrice > 0) {
                            setPrice(newPrice);
                        } else if (newPrice <= 0) {
                            newPrice = 0;
                            setPrice(0);
                        }
                        break;

                    case 4:
                        newPricePorcent =
                            originalPrice -
                            (item.companyCoupon.discountPercent *
                                originalPrice) /
                                100;
                        if (newPricePorcent > 0) {
                            setPrice(newPricePorcent);
                        }
                        break;
                    default:
                        break;
                }
            }
            if (item.unitOfMeasure !== null) {
                const resultado =
                    total +
                    (parseFloat(priceQuantity) + extraPrice) * state -
                    restarCupon;

                // if (quantitySinPromo > 0) {
                //     const suma = (total +
                //         ((parseFloat(priceQuantity) + extraPrice) * quantitySinPromo));
                //     setTotalFinal(totalSinCupon + suma);
                //     return;
                // }
                // else {
                //     setTotalSinCupon(resultado)
                // }
                setTotalFinal(resultado);
            } else {
                let resultado = 0;
                if (item?.companyCoupon?.type === 3) {
                    resultado =
                        total + (extraPrice + newPrice) * state - restarCupon;
                } else if (item?.companyCoupon?.type === 4) {
                    resultado =
                        total +
                        (extraPrice + newPricePorcent) * state -
                        restarCupon;
                } else {
                    resultado =
                        total + (extraPrice + price) * state - restarCupon;
                }
                // console.log(resultado, "resultado")

                // if (quantitySinPromo > 0) {
                //     const suma = (total + (extraPrice + price) * quantitySinPromo);
                //     setTotalFinal(totalSinCupon + suma);
                //     return;
                // }
                // else {
                //     setTotalSinCupon(resultado)
                // }
                setTotalFinal(resultado);
                // console.log(totalFinal, "totalFinal")

                // setTotalFinal(total + (extraPrice + price) * state - restarCupon);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [extraPrice, price, state, priceQuantity, total, setPrice]);

    const onChange = ({ target: { value } }) => {
        setInstructions(value);
    };
    const onChangeQuantity = (quantity) => {
        if (isNaN(quantity) === false) {
            const quantityNum = parseFloat(quantity) || 0;

            setTypeQuantity(quantityNum > 0 ? quantityNum : quantityMin);

            setPriceQuantity(
                quantityNum > 0
                    ? parseFloat(quantityNum * price)
                    : item.unitOfMeasure.minimumPurchase
            );
            const resultado2 = Math.ceil(quantityNum * price, -1);
            if (resultado2 === priceMax) {
                setPriceQuantity(priceMax);
            }
        }
    };
    const onChangePrice = (priceUoms) => {
        // console.log(priceUoms);
        if (isNaN(priceUoms) === false) {
            if (priceUoms === null) {
                priceUoms = parseFloat(item.unitOfMeasure.minimumPurchase);
                setPriceQuantity(priceUoms);
            }
            setPriceQuantity(priceUoms);
            let cantidad = parseFloat(priceUoms / price);
            setTypeQuantity(cantidad);
        }
    };

    const add = () => {
        if (updateOrder) {
            //ORDER YA CREADA
            const found = orderActive.details.findIndex(
                (item) => item.itemId === itemId
            );

            if (found === -1) {
                //Es un Producto nuevo

                function uuidv4() {
                    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                        /[xy]/g,
                        function (c) {
                            // eslint-disable-next-line eqeqeq
                            // eslint-disable-next-line no-mixed-operators
                            var r = (Math.random() * 16) | 0,
                                v = c == 'x' ? r : (r & 0x3) | 0x8;
                            return v.toString(16);
                        }
                    );
                }

                let result = {
                    extraPrice,
                    name: name,
                    price: price,
                    priceUoms: priceQuantity,
                    measurementUnits: typeQuantity ? typeQuantity : quantityMin,
                    typeUoms: nameUnitOfMeasure,
                    altPrice: totalFinal,
                    instructions: instructions,
                    quantity: state,
                    companyId: companyId,
                    itemId: itemId,
                    itemIdUnique: `${uuidv4()}`,
                    options: optionTable,
                };

                orderActive.details.push(result);

                let total = orderActive.subtotal + totalFinal;

                setOrderActiveUpdate({
                    ...orderActive,
                    counterItem: orderActive.details.length,
                    subtotal: total,
                    // details: orderActive.details,
                }); //nuevo hook
                setIsModalVisibleCart(false);
            } else {
                //ya estaba
                if (item.itemSections.length > 0 || item.unitOfMeasure) {
                    //se agregara como nuevo

                    function uuidv4() {
                        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                            /[xy]/g,
                            function (c) {
                                // eslint-disable-next-line eqeqeq
                                // eslint-disable-next-line no-mixed-operators
                                var r = (Math.random() * 16) | 0,
                                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                                return v.toString(16);
                            }
                        );
                    }
                    let result = {
                        extraPrice,
                        name: name,
                        price: price,
                        priceUoms: priceQuantity,
                        measurementUnits: typeQuantity
                            ? typeQuantity
                            : quantityMin,
                        typeUoms: nameUnitOfMeasure,
                        altPrice: totalFinal,
                        instructions: instructions,
                        quantity: state,
                        companyId: companyId,
                        itemId: `${itemId}/${uuidv4()}`,
                        itemIdUnique: `${uuidv4()}`,
                        options: optionTable,
                    };
                    // console.log(result, 'cosa enviada2');

                    orderActive.details.push(result);

                    let total = orderActive.subtotal + totalFinal;

                    setOrderActiveUpdate({
                        ...orderActive,
                        counterItem: orderActive.details.length,
                        subtotal: total,
                    }); //nuevo hook

                    setIsModalVisibleCart(false);
                } else {
                    //Ya estaba en la cesta
                    orderActive.details[found].quantity = state;
                    orderActive.details[found].instructions = instructions;
                    let suma = 0;

                    orderActive.details.forEach((element) => {
                        suma =
                            suma +
                            (element.extraPrice + element.price) *
                                element.quantity;
                    });
                    setOrderGeneral({ ...orderActive, subtotal: suma });
                    setIsModalVisibleCart(false);
                }
            }
        } else {
            const found = orderGeneral.orderLinesList.findIndex(
                (item) => item.itemId === itemId
            );

            if (found === -1) {
                //Es un Producto nuevo

                function uuidv4() {
                    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                        /[xy]/g,
                        function (c) {
                            // eslint-disable-next-line eqeqeq
                            // eslint-disable-next-line no-mixed-operators
                            var r = (Math.random() * 16) | 0,
                                v = c == 'x' ? r : (r & 0x3) | 0x8;
                            return v.toString(16);
                        }
                    );
                }

                let result = {
                    extraPrice,
                    name: name,
                    price: price,
                    priceUoms: priceQuantity,
                    measurementUnits: typeQuantity ? typeQuantity : quantityMin,
                    typeUoms: nameUnitOfMeasure,
                    altPrice: totalFinal,
                    instructions: instructions,
                    quantity: state,
                    companyId: companyId,
                    itemId: itemId,
                    itemIdUnique: `${uuidv4()}`,
                    options: optionTable,
                    originalPrice: item.price,
                    couponComplete: item.companyCoupon
                        ? item.companyCoupon
                        : null,
                };

                orderGeneral.orderLinesList.push(result);
                // setOrder(order);
                // setCounterItem(orderGeneral.orderLinesList.length);
                let total = Subtotal + totalFinal;
                // let total = totalOrder;

                // total = totalFinal ;

                // setTotalOrder(total);
                setOrderGeneral({
                    ...orderGeneral,
                    Subtotal: total,
                }); //nuevo hook
                setIsModalVisible(false);
            } else {
                //ya estaba
                if (item.itemSections.length > 0 || item.unitOfMeasure) {
                    //se agregara como nuevo

                    function uuidv4() {
                        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
                            /[xy]/g,
                            function (c) {
                                // eslint-disable-next-line eqeqeq
                                // eslint-disable-next-line no-mixed-operators
                                var r = (Math.random() * 16) | 0,
                                    v = c == 'x' ? r : (r & 0x3) | 0x8;
                                return v.toString(16);
                            }
                        );
                    }
                    let result = {
                        extraPrice,
                        name: name,
                        price: price,
                        priceUoms: priceQuantity,
                        measurementUnits: typeQuantity
                            ? typeQuantity
                            : quantityMin,
                        typeUoms: nameUnitOfMeasure,
                        altPrice: totalFinal,
                        instructions: instructions,
                        quantity: state,
                        companyId: companyId,
                        itemId: `${itemId}/${uuidv4()}`,
                        itemIdUnique: `${uuidv4()}`,
                        options: optionTable,
                        couponComplete: item.companyCoupon
                            ? item.companyCoupon
                            : null,
                    };
                    // console.log(result, 'cosa enviada2');

                    orderGeneral.orderLinesList.push(result);
                    // setOrder(order);

                    // setCounterItem(orderGeneral.orderLinesList.length);

                    let total = Subtotal + totalFinal;
                    // let total = totalOrder;

                    // total = totalFinal;
                    // setTotalOrder(total);
                    setOrderGeneral({ ...orderGeneral, Subtotal: total }); //nuevo hook
                    setIsModalVisible(false);
                } else {
                    //Ya estaba en la cesta
                    orderGeneral.orderLinesList[found].quantity = state;
                    orderGeneral.orderLinesList[found].instructions =
                        instructions;
                    let suma = 0;

                    orderGeneral.orderLinesList.forEach((element) => {
                        suma =
                            suma +
                            (element.extraPrice + element.price) *
                                element.quantity;
                    });
                    setOrderGeneral({ ...orderGeneral, Subtotal: suma });
                    setIsModalVisible(false);
                }
            }
        }
    };
    const onSectionOptionsChanged = (table) => {
        let tmp = 0.0;
        let options = [];
        for (const [key, value] of Object.entries(table)) {
            let seccOriginal = itemSections.find((e) => e.itemSectionId == key);
            if (seccOriginal && seccOriginal.sectionOptions.length > 0) {
                if (value && value.length > 0) {
                    // eslint-disable-next-line no-loop-func
                    value.forEach((v) => {
                        const opt = {
                            ...seccOriginal.sectionOptions[v],
                            id: seccOriginal.itemSectionId,
                            nameSection: seccOriginal.name,
                        };
                        tmp = tmp + opt.price;
                        options.push(opt);
                    });
                }
            }
        }
        setOptionTable(options);
        setExtraPrice(parseFloat(tmp));
    };

    const obligatorySections = [];
    itemSections
        .filter((item) => item.obligatory && item.available)
        .forEach((item) =>
            item.sectionOptions.forEach((s) => {
                if (!obligatorySections.includes(s.itemSectionId))
                    obligatorySections.push(s.itemSectionId);
            })
        );
    const selectedObligatorySections = [];
    optionTable.forEach((opt) => {
        const original = itemSections.find(
            (sec) => sec.itemSectionId === opt.itemSectionId
        );

        if (
            original.obligatory &&
            !selectedObligatorySections.includes(opt.itemSectionId)
        )
            selectedObligatorySections.push(opt.itemSectionId);
    });
    const notAllSelected =
        obligatorySections.sort().toString() !=
        selectedObligatorySections.sort().toString();
    let invalidUom = false;

    if (item.unitOfMeasure) {
        let originalPrice = item.price;
        if (item.companyCoupon !== null && item.companyCoupon.type === 3) {
            const newPrice = originalPrice - item.companyCoupon.discountPrice;
            quantityMax = parseFloat(
                item.unitOfMeasure.maximumPurchase / newPrice
            );
            quantityMin = parseFloat(
                item.unitOfMeasure.minimumPurchase / newPrice
            );
            priceMax = quantityMax * price;
        }
        if (item.companyCoupon !== null && item.companyCoupon.type === 4) {
            const newPricePorcent =
                originalPrice -
                (item.companyCoupon.discountPercent * originalPrice) / 100;
            quantityMax = parseFloat(
                item.unitOfMeasure.maximumPurchase / newPricePorcent
            );
            quantityMin = parseFloat(
                item.unitOfMeasure.minimumPurchase / newPricePorcent
            );

            priceMax = parseFloat(quantityMax * price);
        } else if (
            item.companyCoupon === null ||
            (item.companyCoupon.type !== 4 && item.companyCoupon.type !== 3)
        ) {
            quantityMax = parseFloat(
                item.unitOfMeasure.maximumPurchase / item.price
            );
            // quantityMax = Math.ceil(
            //     parseFloat(item.unitOfMeasure.maximumPurchase / item.price)
            // );
            quantityMin = parseFloat(
                item.unitOfMeasure.minimumPurchase / item.price
            );
            priceMax = quantityMax * price;
            // console.log(priceMax,"priceMax")
        }
        invalidUom =
            priceQuantity < item.unitOfMeasure.minimumPurchase ||
            priceQuantity > item.unitOfMeasure.maximumPurchase;
    }
    const getNewPrice = (promo, originalPrice) => {
        let price = originalPrice;
        if (promo) {
            if (isValidPromo(promo)) {
                if (promo.type === 3) {
                    const newPrice = price - promo.discountPrice;

                    if (newPrice > 0) {
                        price = newPrice;
                    } else if (newPrice <= 0) {
                        price = 0;
                    }
                } else if (promo.type === 4) {
                    const pricePorcent =
                        (promo.discountPercent * originalPrice) / 100;
                    const newPricePorcent = price - pricePorcent;

                    price = newPricePorcent;
                }
            }
        }

        return price;
    };
    const showItemFreePromo = (promo) => {
        let showPromo = {
            show: false,
            text: '',
        };
        if (promo) {
            const { type } = promo;

            // console.log(promo);

            if (type === 1 || type === 2) {
                showPromo.show = isValidPromo(promo);

                if (type === 1) {
                    showPromo.text = '2x1';
                } else if (type === 2) {
                    showPromo.text = '3x2';
                }
            }
        }

        return showPromo;
    };

    const getCountSave = (promo, originalPrice) => {
        let price = originalPrice;
        let save = '';
        if (promo) {
            if (isValidPromo(promo)) {
                if (promo.type === 3) {
                    const newPrice = price - promo.discountPrice;

                    if (newPrice > 0) {
                        price = newPrice;
                        save = `Ahorra $ ${formatMoney(promo.discountPrice)}.`;
                    } else if (newPrice <= 0) {
                        save = `Ahorra $ ${formatMoney(price)}.`;
                    }
                } else if (promo.type === 4) {
                    // const pricePorcent =
                    //     (promo.discountPercent * originalPrice) / 100;
                    // const newPricePorcent = price - pricePorcent;

                    // price = newPricePorcent;

                    save = `Ahorra el ${promo.discountPercent} %.`;
                }
            }
        }

        return save;
    };

    const showPriceBefore = (promo) => {
        let showPrice = false;
        if (promo) {
            const { type } = promo;

            // console.log(promo);

            if (type === 3 || type === 4) {
                showPrice = isValidPromo(promo);

                // console.log('is valid promo ?', showPrice);
            }
        }

        return showPrice;
    };
    function isValidPromo(promo) {
        const {
            durationType,
            endDate,
            startDate,
            applicableDays,
            timeStart,
            timeEnd,
        } = promo;

        let isValid = false;

        if (durationType === 'range-dates') {
            const startDatePromo = moment.unix(startDate);
            const endDatePromo = moment.unix(endDate);

            if (
                moment(now).isBefore(endDatePromo) &&
                moment(now).isSameOrAfter(startDatePromo)
            ) {
                isValid = true;
            } else {
                isValid = false;
            }
        } else {
            const daysPromoArray = applicableDays.split(',');
            let nowDay = moment().days();
            if (nowDay === 0) {
                //asign sunday value 7
                nowDay = 7;
            }
            //search rest of the days
            const found = daysPromoArray.find(
                (element) => parseInt(element) === nowDay
            );

            if (found) {
                // isValid = true;
                //CHECK TIME
                if (
                    now.hour() >= moment.unix(timeStart, 'h:mma').hour() &&
                    now.hour() <= moment.unix(timeEnd, 'h:mma').hour()
                ) {
                    isValid = true;
                } else {
                    isValid = false;
                }
            } else {
                isValid = false;
            }
        }

        // return true;
        return isValid;
    }
    // console.log(item, "item")
    function trunc(x, posiciones = 0) {
        var s = x.toString();
        var l = s.length;
        var decimalLength = s.indexOf('.') + 1;

        if (l - decimalLength <= posiciones) {
            return x;
        }
        var isNeg = x < 0;
        var decimal = x % 1;
        var entera = isNeg ? Math.ceil(x) : Math.floor(x);

        var decimalFormated = Math.floor(
            Math.abs(decimal) * Math.pow(10, posiciones)
        );

        var finalNum =
            entera +
            (decimalFormated / Math.pow(10, posiciones)) * (isNeg ? -1 : 1);

        return finalNum;
    }

    return (
        <div className='description-item'>
            {!updateOrder && (
                <Row>
                    <Col span={14}>
                        {getNewPrice(item.companyCoupon, item.price) !==
                            item.price && (
                            <p className="banner-discount">
                                <TagFilled />
                                {getCountSave(item.companyCoupon, item.price)}
                            </p>
                        )}
                        {showItemFreePromo(item.companyCoupon).show && (
                            <p className="banner-discount item-free">
                                <TagFilled />
                                {showItemFreePromo(item.companyCoupon).text}
                            </p>
                        )}
                    </Col>
                </Row>
            )}
            <div className="description-item__container-image">
                {itemImage !== null && (
                    <>
                        {/* <img
                            src={
                                itemImage !== null
                                    ? `data:image/${itemImage.extension};base64, ${itemImage.imageData}`
                                    : food
                            }
                            alt='Italian Trulli'
                        ></img> */}
                        <ImageCarousel
                            itemImage={item.itemImage}
                            itemId={item.itemId}
                        />
                    </>
                )}
            </div>
            <div className="description-item__container">
                <Title level={3}>{name}</Title>

                {!updateOrder && (
                    <Title level={5}>
                        ${' '}
                        {formatMoney(
                            getNewPrice(item.companyCoupon, item.price)
                        )}{' '}
                        {nameUnitOfMeasure}
                        {showPriceBefore(item.companyCoupon) &&
                            getNewPrice(item.companyCoupon, item.price) !==
                                item.price && (
                                <Paragraph
                                    type="secondary"
                                    delete
                                    style={{
                                        marginLeft: 8,
                                    }}
                                >
                                    ${formatMoney(item.price)}{' '}
                                    {nameUnitOfMeasure}
                                </Paragraph>
                            )}
                    </Title>
                )}

                {updateOrder && (
                    <Title level={5}>
                        ${formatMoney(price)} {nameUnitOfMeasure}
                    </Title>
                )}
                <p>{description}</p>
            </div>

            {item.unitOfMeasure !== null && (
                <div>
                    <Divider>Unidad de medida</Divider>
                    <Row className="justify-items-uoms">
                        <Col>
                            <p>Seleccionar unidad de medida:</p>
                        </Col>
                    </Row>
                    {/* <Radio.Group onChange={onChangeCheck} value={valueCheck}>
                        <Radio value={2}>Cantidad</Radio>
                        <Radio value={1}>Precio</Radio>
                    </Radio.Group> */}

                    <Row className="justify-items-uoms">
                        <Col xs={24} lg={24}>
                            {/* {valueCheck === 2 && ( */}
                            <p>Cantidad: </p>
                            <Row>
                                {/* <Col xs={12} lg={12}>
                                    <InputNumber
                                        maxLength={2}
                                        min={quantityMin}
                                        max={quantityMax}
                                        step="0.05"
                                        stringMode
                                        style={{
                                            width: '100%',
                                        }}
                                        formatter={(value) =>
                                            `${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )
                                        }
                                        parser={(value) =>
                                            value.replace(/\$\s?|(,*)/g, '')
                                        }
                                        placeholder="2"
                                        value={typeQuantity}
                                        onChange={(value) =>
                                            onChangeQuantity(value)
                                        }
                                    />
                                </Col> */}
                                <Col xs={12} lg={12}>
                                    <InputNumber
                                        precision={2}
                                        maxLength={9}
                                        min={quantityMin}
                                        max={quantityMax}
                                        step="0.05"
                                        stringMode
                                        style={{
                                            width: '100%',
                                        }}
                                        formatter={(value) =>
                                            `${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )
                                        }
                                        parser={(value) =>
                                            value.replace(/\$\s?|(,*)/g, '')
                                        }
                                        placeholder="2"
                                        value={typeQuantity}
                                        onChange={(value) =>
                                            onChangeQuantity(value)
                                        }
                                    />
                                </Col>
                                {/* <Col xs={12} lg={12}> */}
                                <p className="margin-left">
                                    {' '}
                                    {nameUnitOfMeasure}
                                    {' = $'}
                                    {formatMoney(priceQuantity)}
                                </p>
                                {/* </Col> */}
                            </Row>
                            {/* )} */}
                            {/* {valueCheck === 1 && ( */}
                            <p>Precio:</p>

                            <Row>
                                <Col xs={12} lg={12}>
                                    <InputNumber
                                        precision={2}
                                        maxLength={9}
                                        min={item.unitOfMeasure.minimumPurchase}
                                        max={priceMax}
                                        style={{
                                            width: '100%',
                                        }}
                                        formatter={(value) =>
                                            `$ ${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ','
                                            )
                                        }
                                        parser={(value) =>
                                            value.replace(/\$\s?|(,*)/g, '')
                                        }
                                        placeholder="2"
                                        value={priceQuantity}
                                        onChange={(value) =>
                                            onChangePrice(value)
                                        }
                                    />
                                </Col>
                                {/* <Col xs={12} lg={12}> */}
                                {/* {typeQuantity && typeQuantity !== 0 && ( */}
                                <p className="margin-left">
                                    {' '}
                                    {typeQuantity
                                        ? typeQuantity.toFixed(2)
                                        : quantityMin.toFixed(2)}{' '}
                                    {nameUnitOfMeasure}
                                </p>
                                {/* )} */}
                                {/* </Col> */}
                            </Row>
                            {/* )} */}
                        </Col>
                    </Row>
                    <Col>
                        <p>
                            Máximo $
                            {formatMoney(item.unitOfMeasure.maximumPurchase)}
                        </p>
                        <p>
                            Mínimo $
                            {formatMoney(item.unitOfMeasure.minimumPurchase)}
                        </p>
                    </Col>
                    {/* {((priceQuantity && priceQuantity !== 0) || (typeQuantity !== 0)) && (
                        <>
                            <p>Total de {name}: ${formatMoney(priceQuantity)}</p>
                            <p>CANTIDAD: {typeQuantity} {nameUnitOfMeasure}</p>
                        </>
                    )
                    } */}
                </div>
            )}
            {itemSections.length >= 1 && filtradas.length >= 1 && (
                <>
                    <Divider>Ingredientes</Divider>
                </>
            )}

            <Secciones
                itemSections={itemSections}
                onChange={onSectionOptionsChanged}
            />
            <Divider>Instrucciones Especiales</Divider>
            <TextArea
                value={instructions}
                placeholder="Instrucciones Especiales"
                autoSize={{ minRows: 2, maxRows: 6 }}
                onChange={onChange}
            />
            <div className="description-item__container">
                <Divider>Cantidad</Divider>
                <Row className="counter">
                    <Col span={8}>
                        <Button
                            disabled={state === 1}
                            onClick={decrement}
                            danger
                        >
                            -
                        </Button>
                    </Col>
                    <Col span={8}>{state}</Col>
                    <Col span={8}>
                        <Button onClick={increment} danger>
                            +
                        </Button>
                    </Col>
                </Row>
                {item.unitOfMeasure !== null &&
                    priceQuantity !== 0 &&
                    priceQuantity !== null && (
                        <>
                            <Divider></Divider>
                            <Paragraph className="right">
                                {typeQuantity
                                    ? typeQuantity.toFixed(2)
                                    : quantityMin.toFixed(2)}
                                {nameUnitOfMeasure} {name}: $
                                {formatMoney(priceQuantity)}
                                <br />
                                Extras: ${formatMoney(extraPrice)}
                            </Paragraph>
                        </>
                    )}
                <Divider></Divider>

                <Paragraph className="right">
                    {/* Total: ${item.unitOfMeasure ? formatMoney(priceQuantity) : formatMoney(totalFinal) } */}
                    Total: ${formatMoney(totalFinal)}
                </Paragraph>
            </div>
            <Button
                type="primary"
                danger
                block
                onClick={add}
                disabled={notAllSelected || invalidUom}
            >
                Agregar {state}
            </Button>
        </div>
    );
};

export default DescriptionItem;
