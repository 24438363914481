import React, { Fragment, useEffect, useState, useRef } from 'react';
import {
    Typography,
    Skeleton,
    Divider,
    Button,
    Avatar,
    Image,
    List,
    Tabs,
    Card,
    Row,
    Col,
    Spin,
    Empty,
    notification,
    message,
} from 'antd';
import { PlusOutlined, SearchOutlined, MenuOutlined } from '@ant-design/icons';
import food from '../../../../assets/img/jpg/seafood.jpg';
import { getAccessTokenApi } from '../../../../api/auth';
import { getCompleteMenuClient, getMenuGroupApi } from '../../../../api/menu';
import { formatMoney } from '../../../../utils/general';
import Modal from '../../../../components/General/Modal';
import DescriptionItem from '../../../../components/Client/DescriptionItem';
import ShoppingCart from '../ShoppingCart';
import SearchForm from '../SearchForm';

export default function Menu(props) {
    const { orderActive, setreloadPage, setIsModalVisible } = props;
    const { companyId, orderType, orderId } = orderActive;
    const isMounted = useRef(true);
    const { TabPane } = Tabs;
    const [group, setGroup] = useState();
    const [loading, setLoading] = useState(true);
    const [loadingItems, setLoadingItems] = useState(false);
    const { Title } = Typography;
    const token = getAccessTokenApi();
    const [groupSearches, setGroupSearches] = useState([]);
    const [activeKey, setActiveKey] = useState('');
    const [isModalVisible, setIsModalVisibleCart] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [titleModal, setTitleModal] = useState('');
    const [orderActiveUpdate, setOrderActiveUpdate] = useState({
        counterItem: 0,
        subtotal: 0,
        details: [],
    });
    const [showSearchProducts, setShowSearchProducts] = useState(false);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        const data = {
            CompanyId: companyId,
            OrderType: orderType,
            MenuClient: true,
        };

        getCompleteMenuClient(token, data).then((group) => {
            if (isMounted.current) {
                if (group !== undefined) {
                    if (group.length > 0) {
                        let idGroupOne = group[0]['groupId'];

                        const dataApi = {
                            CompanyId: companyId,
                            GroupId: idGroupOne,
                            OrderType: orderType,
                            MenuClient: true,
                        };
                        getMenuGroupApi(token, dataApi).then((resp) => {
                            group[0] = resp;
                            setActiveKey(idGroupOne.toString());
                            setGroup(group);
                            setLoading(false);
                            setGroupSearches([idGroupOne]);
                        });
                    } else {
                        setGroup([]);
                        setLoading(false);
                    }
                } else {
                    notification['error']({
                        message:
                            'Verifique su conexión a internet, si no se resuelve intente más tarde.',
                    });
                }
            }
        });

        return () => {
            setGroup([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId, orderType]);

    const callbackGroup = (key) => {
        let idGroup = parseInt(key);
        const foundIdGroup = groupSearches.find((id) => id === idGroup);
        if (foundIdGroup === undefined) {
            setLoadingItems(true);
            let dataApi = {
                CompanyId: companyId,
                GroupId: idGroup,
                OrderType: orderType,
                MenuClient: true,
            };
            getMenuGroupApi(token, dataApi).then((resp) => {
                if (isMounted.current) {
                    if (resp !== undefined) {
                        setGroupSearches([...groupSearches, idGroup]);
                        const found = group.findIndex(
                            (item) => item.groupId === resp.groupId
                        );
                        group[found] = resp;
                        setGroup(group);
                        setActiveKey(idGroup.toString());
                        setLoadingItems(false);
                    }

                    if (resp === undefined) {
                        message.error(
                            'Verifique su conexión a internet, si no se resuelve intente más tarde.'
                        );
                        setActiveKey(idGroup.toString());
                        setLoadingItems(false);
                    }
                } else {
                    console.log('NO HARAS NADA');
                }
            });
        } else {
            setActiveKey(key);
        }
    };

    const showModal = (item, title) => {
        if (title === 'Order') {
            //MOSTAR CARRITO
            setContextModal(
                <ShoppingCart
                    orderActive={orderActiveUpdate}
                    setIsModalVisibleCart={setIsModalVisibleCart}
                    setOrderActiveUpdate={setOrderActiveUpdate}
                    setreloadPage={setreloadPage}
                    orderId={orderId}
                    setIsModalVisible={setIsModalVisible}
                />
            );
            setTitleModal('Orden');
            setIsModalVisibleCart(true);
        } else {
            setContextModal(
                <DescriptionItem
                    item={item}
                    setIsModalVisibleCart={setIsModalVisibleCart}
                    updateOrder
                    orderActive={orderActiveUpdate}
                    setOrderActiveUpdate={setOrderActiveUpdate}
                />
            );
            setTitleModal(title);
            setIsModalVisibleCart(true);
        }
    };

    const listData = [];
    for (let i = 0; i < 5; i++) {
        listData.push({
            href: 'https://ant.design',
            title: `menuClient ${i}`,
            avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
            description:
                'Ant Design, a design language for background applications, is refined by Ant UED Team.',
            content:
                'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
        });
    }
    return (
        <>
            {loading === false && (
                <div className='update-order'>
                    <Spin spinning={loadingItems} tip='Cargando...'>
                        <Row>
                            <Col span={24}>
                                {/* {!showSearchProducts && (
                                    <Button
                                        type='primary'
                                        block
                                        ghost
                                        icon={<SearchOutlined />}
                                        onClick={() =>
                                            setShowSearchProducts(true)
                                        }
                                    >
                                        Buscar un producto
                                    </Button>
                                )} */}
                                {showSearchProducts && (
                                    <Button
                                        type='primary'
                                        block
                                        ghost
                                        icon={<MenuOutlined />}
                                        onClick={() =>
                                            setShowSearchProducts(false)
                                        }
                                    >
                                        Ver un menu
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        {!showSearchProducts && (
                            <Tabs
                                activeKey={activeKey}
                                defaultActiveKey={activeKey}
                                centered
                                className={
                                    'menuCustomer ' +
                                    (orderActiveUpdate.counterItem > 0
                                        ? 'active-scroll-modal'
                                        : '')
                                }
                                onChange={callbackGroup}
                            >
                                {group.length > 0 &&
                                    group.map((group) => {
                                        return (
                                            <Fragment
                                                key={'group' + group.groupId}
                                            >
                                                {group.categories.length >
                                                    0 && (
                                                    <TabPane
                                                        tab={group.name}
                                                        key={group.groupId}
                                                    >
                                                        {group.categories
                                                            .length > 0 &&
                                                            group.categories.map(
                                                                (cat) => {
                                                                    return (
                                                                        <Fragment
                                                                            key={
                                                                                cat.categoryId
                                                                            }
                                                                        >
                                                                            {cat
                                                                                .items
                                                                                .length >
                                                                                0 && (
                                                                                <Divider
                                                                                    orientation='left'
                                                                                    plain
                                                                                >
                                                                                    <Title
                                                                                        level={
                                                                                            4
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            cat.name
                                                                                        }
                                                                                    </Title>
                                                                                </Divider>
                                                                            )}

                                                                            {cat
                                                                                .items
                                                                                .length >
                                                                                0 &&
                                                                                cat.items.map(
                                                                                    (
                                                                                        item
                                                                                    ) => {
                                                                                        return (
                                                                                            <Card
                                                                                                className='card-dish'
                                                                                                key={
                                                                                                    item.itemId
                                                                                                }
                                                                                            >
                                                                                                <Row>
                                                                                                    <Col
                                                                                                        span={
                                                                                                            14
                                                                                                        }
                                                                                                    >
                                                                                                        <Title
                                                                                                            level={
                                                                                                                4
                                                                                                            }
                                                                                                        >
                                                                                                            {
                                                                                                                item.name
                                                                                                            }
                                                                                                        </Title>
                                                                                                        {!item.available && (
                                                                                                            <p className='card-dish__noAvailable'>
                                                                                                                No
                                                                                                                está
                                                                                                                disponible
                                                                                                            </p>
                                                                                                        )}
                                                                                                        <p className='card-dish__description'>
                                                                                                            {
                                                                                                                item.description
                                                                                                            }
                                                                                                        </p>
                                                                                                        <p>
                                                                                                            {' '}
                                                                                                            $
                                                                                                            {formatMoney(
                                                                                                                item.price
                                                                                                            )}
                                                                                                        </p>
                                                                                                        <Button
                                                                                                            icon={
                                                                                                                <PlusOutlined />
                                                                                                            }
                                                                                                            danger
                                                                                                            onClick={() =>
                                                                                                                showModal(
                                                                                                                    item,
                                                                                                                    ''
                                                                                                                )
                                                                                                            }
                                                                                                            disabled={
                                                                                                                item.available
                                                                                                                    ? false
                                                                                                                    : true
                                                                                                            }
                                                                                                        >
                                                                                                            Agregar
                                                                                                        </Button>
                                                                                                    </Col>
                                                                                                    <Col
                                                                                                        span={
                                                                                                            10
                                                                                                        }
                                                                                                        className='text-right'
                                                                                                    >
                                                                                                        {item.itemImage !==
                                                                                                            null && (
                                                                                                            <Image
                                                                                                                width={
                                                                                                                    120
                                                                                                                }
                                                                                                                src={
                                                                                                                    item.itemImage !==
                                                                                                                    null
                                                                                                                        ? `data:image/${item.itemImage.extension};base64, ${item.itemImage.imageData}`
                                                                                                                        : food
                                                                                                                }
                                                                                                            />
                                                                                                        )}
                                                                                                    </Col>
                                                                                                </Row>
                                                                                            </Card>
                                                                                        );
                                                                                    }
                                                                                )}
                                                                            {/* </Spin> */}
                                                                        </Fragment>
                                                                    );
                                                                }
                                                            )}
                                                    </TabPane>
                                                )}
                                            </Fragment>
                                        );
                                    })}
                            </Tabs>
                        )}
                        {group.length === 0 && !showSearchProducts && (
                            <Empty description={'No hay productos'} />
                        )}
                    </Spin>
                    {showSearchProducts && (
                        <SearchForm
                            orderActiveUpdate={orderActiveUpdate}
                            setOrderActiveUpdate={setOrderActiveUpdate}
                        />
                    )}
                    {orderActiveUpdate.counterItem > 0 && (
                        <div className='menu-costumer-button'>
                            <Button
                                type='primary'
                                block
                                danger
                                size='large'
                                onClick={() => showModal(undefined, 'Order')}
                            >
                                <Row>
                                    <Col
                                        span={4}
                                        className='menu-costumer-button__left'
                                    >
                                        <div className='counter-square'>
                                            {orderActiveUpdate.counterItem}
                                        </div>
                                    </Col>

                                    <Col
                                        span={16}
                                        className='menu-costumer-button__center'
                                    >
                                        Ver Canasta
                                    </Col>
                                </Row>
                            </Button>
                        </div>
                    )}
                </div>
            )}

            {loading === true && (
                <List
                    itemLayout='vertical'
                    size='large'
                    dataSource={listData}
                    renderItem={(item) => (
                        <List.Item key={item.title}>
                            <Skeleton loading={loading} active avatar>
                                <List.Item.Meta
                                    avatar={<Avatar src={item.avatar} />}
                                    title={<a href={item.href}>{item.title}</a>}
                                    description={item.description}
                                />
                                {item.content}
                            </Skeleton>
                        </List.Item>
                    )}
                />
            )}

            <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisibleCart}
                title={titleModal}
                footer={false}
                destroyOnClose
            >
                {contextModal}
            </Modal>
        </>
    );
}
