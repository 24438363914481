import React, { useState, useEffect, useRef } from 'react';
import {
    Form,
    Input,
    Button,
    Col,
    Row,
    Card,
    Divider,
    Typography,
    Image,
    List,
    message,
    Result,
    notification,
    Spin,
} from 'antd';
import {
    // LoadingOutlined,
    // SearchOutlined,
    PlusOutlined,
    TagFilled,
} from '@ant-design/icons';
import moment from 'moment';
import useOrder from '../../../../hooks/useOrder';
import Food from '../../../../assets/img/jpg/seafood.jpg';
import { formatMoney, userLogOut } from '../../../../utils/general';
import DescriptionItem from '../../../../components/Client/DescriptionItem';
import Modal from '../../../../components/General/Modal';
import { getAccessTokenApi } from '../../../../api/auth';
import { getCompanyIdApi } from '../../../../api/company';
import { getMenuItemsApi } from '../../../../api/menu';

import './SearchForm.scss';

const { Title, Text } = Typography;

const SearchForm = ({ orderActiveUpdate, setOrderActiveUpdate }) => {
    const isMounted = useRef(true);
    const { orderGeneral } = useOrder();
    const { orderLinesList } = orderGeneral;
    const token = getAccessTokenApi();
    const [loading, setLoading] = useState(false);
    const [counterItem, setCounterItem] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [contextModal, setContextModal] = useState(null);
    const [titleModal, setTitleModal] = useState('');
    const [searchWord, setSearchWord] = useState('');
    const [items, setItems] = useState([]);
    const { Search } = Input;
    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    useEffect(() => {
        setCounterItem(orderLinesList.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderGeneral]);

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const onSearch = (value) => {
        if (value.trim().length >= 3) {
            setLoading(true);
            setSearchWord(value);
            const token = getAccessTokenApi();
            const idCompany = getCompanyIdApi();

            getMenuItemsApi(token, idCompany, value).then((response) => {
                console.log(response);
                if (isMounted.current) {
                    setLoading(false);
                    if (response !== undefined) {
                        setItems(response);
                    } else {
                        setItems([]);
                        notification['error']({
                            message:
                                'Hubo un problema, revise su conexión a internet, si no se resuelve intentelo más tarde.',
                        });
                    }
                }
                // setLoading(false);
                // setItems(response);
                // console.log(response);
            });
        } else {
            if (value.trim().length >= 1) {
                message.warning('Ingrese mas letras');
            }
        }
    };

    const showModal = (item, title) => {
        setContextModal(
            <DescriptionItem
                item={item}
                setIsModalVisible={setIsModalVisible}
                updateOrder
                orderActive={orderActiveUpdate}
                setOrderActiveUpdate={setOrderActiveUpdate}
            />
        );
        setTitleModal(title);
        setIsModalVisible(true);
    };

    return (
        <>
            <Form {...layout} name='searchMenu' className='form-search-menu'>
                <Form.Item name='itemName' style={{ marginBottom: 0 }}>
                    <Search
                        allowClear
                        placeholder='Buscar producto del menú'
                        enterButton='Buscar'
                        size='large'
                        loading={loading}
                        onSearch={onSearch}
                    />
                </Form.Item>
            </Form>

            <div
                className={
                    'menu-search' +
                    (counterItem > 0 ? ' search-active-scroll' : '')
                }
            >
                <Divider style={{ marginTop: 10 }} />

                {items.length > 0 && !loading && (
                    <List
                        itemLayout='horizontal'
                        dataSource={items}
                        locale='No se ha encontrado productos'
                        renderItem={(item) => (
                            <CardItem item={item} showModal={showModal} />
                        )}
                    />
                )}

                {items.length === 0 && searchWord !== '' && !loading && (
                    <Result
                        status='404'
                        title='No se encontraron resultados'
                        subTitle='Intenta con un termino de búsqueda diferente.'
                    />
                )}

                {loading && searchWord !== '' && <Spin size='large' />}
            </div>
            {/* {orderActiveUpdate.counterItem > 0 && (
                <div className='menu-costumer-button'>
                    <Button
                        type='primary'
                        block
                        danger
                        size='large'
                        onClick={() => showModal(undefined, 'Order')}
                    >
                        <Row>
                            <Col
                                span={4}
                                className='menu-costumer-button__left'
                            >
                                <div className='counter-square'>
                                    {orderActiveUpdate.counterItem}
                                </div>
                            </Col>

                            <Col
                                span={16}
                                className='menu-costumer-button__center'
                            >
                                Ver Canasta
                            </Col>
                        </Row>
                    </Button>
                </div>
            )} */}

            <Modal
                isVisible={isModalVisible}
                setIsVisible={setIsModalVisible}
                title={titleModal}
                footer={false}
                destroyOnClose
            >
                {contextModal}
            </Modal>
        </>
    );
};

export default SearchForm;

function CardItem({ item, showModal }) {
    const { available, itemImage } = item;
    const now = moment();

    const showPriceBefore = (promo) => {
        let showPrice = false;
        if (promo) {
            const { type } = promo;

            if (type === 3 || type === 4) {
                showPrice = isValidPromo(promo);
            }
        }

        return showPrice;
    };

    const showItemFreePromo = (promo) => {
        let showPromo = {
            show: false,
            text: '',
        };
        if (promo) {
            const { type } = promo;

            if (type === 1 || type === 2) {
                showPromo.show = isValidPromo(promo);

                if (type === 1) {
                    showPromo.text = '2x1';
                } else if (type === 2) {
                    showPromo.text = '3x2';
                }
            }
        }

        return showPromo;
    };

    const getNewPrice = (promo, originalPrice) => {
        let price = originalPrice;
        if (promo) {
            if (isValidPromo(promo)) {
                if (promo.type === 3) {
                    const newPrice = price - promo.discountPrice;

                    if (newPrice > 0) {
                        price = newPrice;
                    }
                } else if (promo.type === 4) {
                    const pricePorcent =
                        (promo.discountPercent * originalPrice) / 100;
                    const newPricePorcent = price - pricePorcent;

                    price = newPricePorcent;
                }
            }
        }

        return price;
    };

    const getCountSave = (promo, originalPrice) => {
        let price = originalPrice;
        let save = '';
        if (promo) {
            if (isValidPromo(promo)) {
                if (promo.type === 3) {
                    const newPrice = price - promo.discountPrice;

                    if (newPrice > 0) {
                        price = newPrice;
                    }

                    const difference = originalPrice - price;
                    save = `Ahorra $ ${formatMoney(difference)}.`;
                } else if (promo.type === 4) {
                    // const pricePorcent =
                    //     (promo.discountPercent * originalPrice) / 100;
                    // const newPricePorcent = price - pricePorcent;

                    // price = newPricePorcent;

                    save = `Ahorra el ${promo.discountPercent} %.`;
                }
            }
        }

        return save;
    };

    function isValidPromo(promo) {
        const {
            durationType,
            endDate,
            startDate,
            applicableDays,
            timeStart,
            timeEnd,
        } = promo;

        let isValid = false;

        if (durationType === 'range-dates') {
            const startDatePromo = moment.unix(startDate);
            const endDatePromo = moment.unix(endDate);

            if (
                moment(now).isBefore(endDatePromo) &&
                moment(now).isSameOrAfter(startDatePromo)
            ) {
                isValid = true;
            } else {
                isValid = false;
            }
        } else {
            const daysPromoArray = applicableDays.split(',');
            let nowDay = moment().days();
            if (nowDay === 0) {
                //asign sunday value 7
                nowDay = 7;
            }
            //search rest of the days
            const found = daysPromoArray.find(
                (element) => parseInt(element) === nowDay
            );

            if (found) {
                // isValid = true;
                //CHECK TIME
                if (
                    moment(now).isBetween(
                        moment.unix(timeStart, 'h:mma'),
                        moment.unix(timeEnd, 'h:mma')
                    )
                ) {
                    isValid = true;
                } else {
                    isValid = false;
                }
            } else {
                isValid = false;
            }
        }

        // return true;
        return isValid;
    }

    return (
        <Card className='card-menu-search' style={{ marginBottom: 20 }}>
            <Row>
                <Col span={14}>
                    {/* <Title level={4} style={{ marginBottom: 0 }} className="card-menu-search__title">
                        {name}
                    </Title> */}
                    {!available && (
                        <p className='card-menu-search__noAvailable'>
                            No está disponible
                        </p>
                    )}
                    {/* <Text className="card-menu-search__description">{description}</Text>
                    <p> ${formatMoney(price)}</p> */}
                    {/* cosa roja */}
                    {getNewPrice(item.companyCoupon, item.price) !==
                        item.price && (
                        <p className='banner-discount'>
                            <TagFilled />
                            {getCountSave(item.companyCoupon, item.price)}
                        </p>
                    )}
                    {showItemFreePromo(item.companyCoupon).show && (
                        <p className='banner-discount item-free'>
                            <TagFilled />
                            {showItemFreePromo(item.companyCoupon).text}
                        </p>
                    )}

                    <Title level={4}>{item.name}</Title>
                    {!item.available && (
                        <p className='card-dish__noAvailable'>
                            No está disponible
                        </p>
                    )}
                    <p className='card-dish__description'>{item.description}</p>
                    <p>
                        {/* precio normal */}$
                        {formatMoney(
                            getNewPrice(item.companyCoupon, item.price)
                        )}
                        {/* precio con descuento */}
                        {showPriceBefore(item.companyCoupon) &&
                            getNewPrice(item.companyCoupon, item.price) !==
                                item.price && (
                                <Text
                                    type='secondary'
                                    delete
                                    style={{
                                        marginLeft: 8,
                                    }}
                                >
                                    ${formatMoney(item.price)}
                                </Text>
                            )}
                    </p>
                    <Button
                        danger
                        icon={
                            <PlusOutlined
                                className={available ? 'color-red ' : ''}
                            />
                        }
                        onClick={() => showModal(item, '')}
                        disabled={available ? false : true}
                    >
                        Agregar
                    </Button>
                </Col>
                <Col span={10} className='text-right'>
                    {itemImage !== null && (
                        <Image
                            width={120}
                            src={
                                itemImage !== null
                                    ? `data:image/${itemImage.extension};base64, ${itemImage.imageData}`
                                    : Food
                            }
                        />
                    )}
                </Col>
            </Row>
        </Card>
    );
}
