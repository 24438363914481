import React, { useState, useEffect } from 'react';
import {
    Form,
    Input,
    Select,
    Tooltip,
    Button,
    Spin,
    Row,
    Col,
    notification,
} from 'antd';
import {
    UserOutlined,
    LockOutlined,
    MailOutlined,
    PhoneOutlined,
    ShopOutlined,
    EditOutlined,
    QuestionCircleOutlined,
} from '@ant-design/icons';
import { getCompanyIdApi } from '../../../../api/company';
import { createClientApi } from '../../../../api/user';
import { getAccessTokenApi } from '../../../../api/auth';
import { validatePassword } from '../../../../utils/formValidation';
import Modal from '../../../../components/General/Modal';
import AddressForm from '../../../../components/Client/AddressForm';

import './AddUserForm.scss';

const AddUserForm = ({ setIsVisibleModal, setreloadUsers }) => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const addUser = async (values) => {
        setLoading(true);
        const token = getAccessTokenApi();
        const companyId = getCompanyIdApi();
        let formData = values;

        formData['phoneNumber'] = formData['phoneNumber'].toString();
        formData['UserCompanyId'] = companyId;

        const result = await createClientApi(token, formData);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
            setLoading(false);
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                if (result.status === 400) {
                    if (result.errors.PhoneNumber) {
                        msg =
                            'El campo Teléfono no es un número de teléfono válido.';
                    }
                }

                notification['error']({
                    message: msg,
                });
                setLoading(false);
            } else {
                //recreo correctamenta
                form.resetFields();
                notification['success']({
                    message: 'Usuario creado exitosamente',
                });
                setLoading(false);

                setIsVisibleModal(false);
                setreloadUsers(true);
            }
        }
    };

    return (
        <div className="add-user-form">
            <AddForm addUser={addUser} loading={loading} form={form} />
        </div>
    );
};

export default AddUserForm;

function AddForm(props) {
    const { addUser, loading, form } = props;
    const [isModalVisibleAddress, setIsModalVisibleAddress] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [addressRest, setAddressRest] = useState({
        address: null,
        zipCode: null,
        neighborhood: null,
        city: null,
        state: null,
    });

    useEffect(() => {
        if (
            addressRest.neighborhood &&
            addressRest.address &&
            addressRest.zipCode &&
            addressRest.city &&
            addressRest.state
        ) {
            form.setFieldsValue({
                Address: addressRest.address,
                ZipCode: addressRest.zipCode.toString(),
                Neighborhood: addressRest.neighborhood,
                City: addressRest.city,
                State: addressRest.state,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressRest]);

    const { Option } = Select;
    const tailLayoutButton = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };

    return (
        <Spin tip="Creando cuenta..." spinning={loading}>
            <Form
                form={form}
                className="form-add"
                onFinish={addUser}
                initialValues={{ Active: true }}
            >
                <Row gutter={24}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="Name"
                            label="Nombre del usuario"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese un nombre',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Nombre"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="LastName"
                            label="Apellido del usuario"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese un apellido',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Apellido"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label="Correo Electrónico"
                            name="Email"
                            hasFeedback
                            rules={[
                                {
                                    type: 'email',
                                    message:
                                        'El correo electrónico no es válido',
                                },
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese un correo electrónico',
                                },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="Correo Electronico"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="phoneNumber"
                            label={
                                <span>
                                    Teléfono&nbsp;
                                    <Tooltip title="Tú número telefónico debe contener 10 dígitos">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingresa tú número telefónico',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            value.toString().length === 10
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            'Por favor ingresa tú número telefónico a 10 dígitos'
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                prefix={<PhoneOutlined />}
                                placeholder="Teléfono"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label="Rol"
                            name="UserType"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor seleccione un rol',
                                },
                            ]}
                        >
                            <Select placeholder="Selecciona un rol">
                                <Option value={3}>Cliente</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Row gutter={24}>
                    <Col xs={24} md={24}>
                        <Form.Item
                            label="Contraseña"
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese una contraseña',
                                },
                                () => ({
                                    validator(rule, value) {
                                        if (validatePassword(value)) {
                                            return Promise.resolve();
                                        } else if (!validatePassword(value)) {
                                            return Promise.reject(
                                                'La contrase\u00F1a nueva debe de tener  mínimo 6 o más caracteres, por lo menos una may\u00FAscula, un número  y un car\u00E1cter especial'
                                            );
                                        }
                                    },
                                }),
                            ]}
                            hasFeedback
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder="Contraseña"
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="confirmpassword"
                            label="Repetir contraseña"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese la contraseña anterior',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            getFieldValue('password') === value
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            'Las dos contraseñas ingresadas no son iguales'
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input.Password
                                prefix={<LockOutlined />}
                                placeholder="Repetir contraseña"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    className="perfil-form__container-button"
                    hidden={loading}
                    {...tailLayoutButton}
                >
                    <Button
                        icon={<EditOutlined />}
                        type="primary"
                        size="large"
                        block
                        onClick={() => setIsModalVisibleAddress(true)}
                        style={{ marginBottom: 20 }}
                        ghost
                    >
                        Agregar dirección
                    </Button>
                </Form.Item>

                <Form.Item
                    name="Address"
                    label="Calle y Número"
                    hidden={loading}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item name="ZipCode" label="Código Postal" hasFeedback>
                    <Input disabled placeholder="Código Postal" />
                </Form.Item>

                <Form.Item
                    name="Neighborhood"
                    label="Colonia"
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    name="City"
                    label="Ciudad"
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    disabled
                    name="State"
                    label="Estado"
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    name="Companies"
                    label="Nombre de la compañia del cliente"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                            whitespace: true,
                        },
                    ]}
                >
                    <Input
                        prefix={<ShopOutlined />}
                        placeholder="Nombre de la compañia"
                    />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={disableButton}
                    >
                        Crear usuario
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                isVisible={isModalVisibleAddress}
                setIsVisible={setIsModalVisibleAddress}
                title={'Dirección del establecimiento'}
                footer={false}
                destroyOnClose
            >
                <AddressForm
                    type="adminPerfil"
                    setAddressRest={setAddressRest}
                    setIsModalVisibleAddress={setIsModalVisibleAddress}
                    setDisableButton={setDisableButton}
                />
            </Modal>
        </Spin>
    );
}
