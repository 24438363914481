import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Divider, Row, Col, Skeleton, notification } from 'antd';
import moment from 'moment';
import {
    getUrlParameter,
    formatMoney,
    isMobile,
    formatPhoneNumber,
} from '../../../utils/general';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getCompanyIdApi, getCompanyNameApi } from '../../../api/company';
import { getOrderAdmin } from '../../../api/order';
import { COMPANY_DISTANCE_MAX } from '../../../utils/constants';
import { userLogOut } from '../../../utils/general';

import './OrderPrint.scss';

const OrderPrint = () => {
    let history = useHistory();
    const idOrder = getUrlParameter('idOrder');
    const deliveryMaxKm = parseFloat(
        localStorage.getItem(COMPANY_DISTANCE_MAX)
    );
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();
    const companyName = getCompanyNameApi();
    const [order, setOrder] = useState(null);
    let [orderDateTime, setOrderDateTime] = useState(null);
    const [loading, setLoading] = useState(null);
    const [payTypeOrder, setpayTypeOrder] = useState({
        showChange: false,
        textChange: '',
    });

    useEffect(() => {
        if (token == null) {
            userLogOut();
        }
    }, [token]);

    useEffect(() => {
        setLoading(true);
        if (idOrder === undefined) {
            history.push(`/admin/orden`);
            return true;
        }

        const data = {
            CompanyId: companyId,
            OrderId: parseInt(idOrder),
        };

        getOrderAdmin(token, data).then((res) => {
            if (res?.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1000);
            }
            if (res) {
                setOrder(res);
                // let orderDateTime = new Date(res.orderDateTime);
                let orderDateTime = {
                    date: moment.unix(res.orderDateTimeUnix).format('L'),
                    time: moment.unix(res.orderDateTimeUnix).format('h:mm A'),
                };

                if (res.payType === 1) {
                    if (res.changeOf !== 0) {
                        if (res.changeOf - res.total !== 0) {
                            const payTypeOrder = `Necesita $${formatMoney(
                                res.changeOf - res.total
                            )} de cambio`;
                            setpayTypeOrder({
                                showChange: true,
                                textChange: payTypeOrder,
                            });
                        }
                    }
                }

                setOrderDateTime(orderDateTime);
                setLoading(false);
                window.print();
                if (isMobile() === true) {
                    setTimeout(() => {
                        history.push(`/admin/orden`);
                    }, 4000);
                } else {
                    history.push(`/admin/orden`);
                }
            } else {
                notification['error']({
                    message: 'Ocurrió un error, intntelo mas tarde.',
                });
                setTimeout(() => {
                    history.push(`/admin/orden`);
                }, 2000);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idOrder]);

    return (
        <>
            {order === null && loading === true && (
                <Skeleton tip='Cargando...' spinning={loading}></Skeleton>
            )}

            {order !== null && loading === false && (
                <Layout className='order-print'>
                    <Row className='order-print__top'>
                        <Col span={24}>
                            <p>
                                {companyName} Orden {order.orderNum}
                            </p>
                        </Col>
                    </Row>

                    <Row className='order-print__title'>
                        <Col span={24}>
                            <h1>
                                {order.orderTypeText === 'ALaMesa'
                                    ? 'A la mesa'
                                    : order.orderTypeText === 'ADomicilio'
                                    ? 'A domicilio'
                                    : order.orderTypeText}
                            </h1>
                        </Col>
                    </Row>

                    <Row className='order-print__container'>
                        <Col span={24}>
                            <p className='mb-0'>Cliente: {order.clientName}</p>
                        </Col>

                        {order.orderType === 2 && (
                            <Col span={24}>
                                <p className='mb-0'>
                                    Teléfono:{' '}
                                    {formatPhoneNumber(order.clientPhoneNumber)}
                                </p>
                            </Col>
                        )}

                        <Col span={24}>
                            <p>Dirección: {order.clientAddress}</p>
                        </Col>

                        {order.orderType === 2 && (
                            <Col span={24}>
                                <p className='mb-0'>
                                    Distancia: {order.distance} Km
                                </p>
                                {order.distance > deliveryMaxKm && (
                                    <h5>Envío fuera del rango de entrega</h5>
                                )}
                            </Col>
                        )}

                        <Col span={24}>
                            <p className='mb-0'>
                                Método de pago:{' '}
                                {order.payType === 1
                                    ? 'Efectivo'
                                    : 'Tarjeta bancaria'}
                            </p>
                        </Col>
                        {payTypeOrder.showChange && (
                            <p>{payTypeOrder.textChange}</p>
                        )}
                    </Row>

                    <Row className='order-print__container'>
                        <Col span={24}>
                            <p>
                                {orderDateTime.date} {orderDateTime.time}
                            </p>
                        </Col>
                        <Col span={24}>
                            <p>
                                Fecha de entrega:{' '}
                                {moment
                                    .unix(order.estimatedDeliveryDate)
                                    .format('dddd')}
                                ,{' '}
                                {moment
                                    .unix(order.estimatedDeliveryDate)
                                    .format('L')}
                            </p>
                        </Col>
                    </Row>

                    <Divider className='divider' />
                    <Row gutter={[8, 0]}>
                        <Col span={6}>
                            <h1>Cant.</h1>
                        </Col>
                        <Col span={10}>
                            <h1>Descripción</h1>
                        </Col>
                        <Col span={8}>
                            <h1>Precio</h1>
                        </Col>
                    </Row>
                    {order.details.map((order, index) => {
                        return (
                            <div key={`div-${index}`}>
                                <Row gutter={[8, 0]} key={order.detailId}>
                                    <Col span={6}>
                                        <p className='margin-items'>
                                            {order.quantity}
                                        </p>
                                    </Col>
                                    <Col span={10}>
                                        <p className='margin-items'>
                                            {order.itemName}{' '}
                                            {order.orderDetailUom !== null
                                                ? `(${
                                                      order.orderDetailUom.units
                                                  }${' '}${
                                                      order.orderDetailUom
                                                          .unitTypeName
                                                  }) `
                                                : ''}
                                            {order.instructions !== ''
                                                ? `(${order.instructions})`
                                                : ''}
                                        </p>
                                    </Col>
                                    <Col span={8}>
                                        <h1 className='margin-items'>
                                            ${formatMoney(order.linePrice)}
                                        </h1>
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]} key={index}>
                                    <Col span={6}>
                                        <p></p>
                                    </Col>
                                    <Col span={10}>
                                        {order.selectedOptions.map((option) => (
                                            <Row key={option.id}>
                                                <Col span={24}>
                                                    <p className='section-items'>
                                                        {`${option.name}`}
                                                    </p>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                    <Col span={8}>
                                        {order.selectedOptions.map((option) => (
                                            <Row key={option.id}>
                                                <Col span={24}>
                                                    <p className='section-items'>
                                                        {option.price !== null
                                                            ? `$${formatMoney(
                                                                  option.price *
                                                                      order.quantity
                                                              )}`
                                                            : ''}
                                                    </p>
                                                </Col>
                                            </Row>
                                        ))}
                                    </Col>
                                </Row>
                            </div>
                        );
                    })}

                    <Divider className='divider-dashed' dashed />

                    <Row className='order-print__container-right'>
                        <Col span={24}>
                            <p>SubTotal: ${formatMoney(order.subTotal)}</p>
                            <p>Envío: ${formatMoney(order.delivery)}</p>
                            {/* <p>Propina: ${formatMoney(order.tip)}</p> */}
                            <p className='last'>
                                Total: ${formatMoney(order.total)}
                            </p>
                        </Col>
                    </Row>

                    <Divider className='divider-dashed' dashed />

                    <Row className='order-print__top'>
                        <Col span={24}>
                            <p>Gracias por hacer su pedido en {companyName} </p>
                        </Col>
                    </Row>
                </Layout>
            )}
        </>
    );
};

export default OrderPrint;
