import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Layout, Tabs } from 'antd';
import {
    DollarCircleOutlined,
    BarChartOutlined,
    CarOutlined,
} from '@ant-design/icons';
import { suscriptionType } from '../../../utils/general';
import HeaderTop from '../../../components/General/HeaderTop';
import SellTable from '../../../components/Admin/SellTable';
import TopSelling from '../../../components/Admin/TopSelling';
import AuthAdminForm from '../../../components/Admin/AuthAdminForm';
import useAuth from '../../../hooks/useAuth';
import AdminHome from '../../../pages/Admin/Perfil';
import { getAccessTokenApi } from '../../../api/auth';
import { getCompanyIdApi } from '../../../api/company';
import './Report.scss';
import HomeDeliveries from '../../../components/Admin/HomeDeliveries/HomeDeliveries';
import { getCompanySuscriptionApi } from '../../../api/company';

const Report = () => {
    const { user } = useAuth();
    const { Content } = Layout;
    const { TabPane } = Tabs;
    const [isAuth, setIsAuth] = useState(false);
    const [roleUser, setRoleUser] = useState('');
    const companyId = getCompanyIdApi();
    const [suscriptionCompany, setSuscriptionCompany] = useState('Basic');

    useEffect(() => {
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        setRoleUser(roleId);
    }, [user]);

    useEffect(() => {
        const token = getAccessTokenApi();
        if (companyId) {
            getCompanySuscriptionApi(token, companyId)
                .then((response) => {
                    if (response) {
                        if (response.statusCode === 200) {
                            const result = response.result;
                            setSuscriptionCompany(
                                suscriptionType(result.suscriptionType)
                            );

                            if (result.suscriptionType === 3) {
                                setIsAuth(true);
                            }
                        }
                    }
                })
                .catch((err) => {});
        }
    }, [companyId]);

    if (roleUser === 'Operator') {
        return (
            <>
                <Route path="/admin" component={AdminHome} />
                <Redirect to="/admin" />
            </>
        );
    }

    return (
        <Layout className="report">
            <Content className="report__content">
                <HeaderTop
                    title={'Reportes'}
                    // backIcon={true}
                    backUrl="/admin"
                />
                {/* En la version Plus quitar el isAuth */}
                {roleUser === 'Admin' && isAuth && (
                    <Tabs defaultActiveKey="1" centered className="report-tabs">
                        <TabPane
                            tab={
                                <span className="report-tabs__title">
                                    <DollarCircleOutlined />
                                    Control de Ventas
                                </span>
                            }
                            key="1"
                        >
                            <SellTable />
                        </TabPane>
                        <TabPane
                            tab={
                                <span className="report-tabs__title">
                                    <BarChartOutlined />
                                    Ranking
                                </span>
                            }
                            key="2"
                        >
                            <TopSelling allRest={false}></TopSelling>
                        </TabPane>
                        {suscriptionCompany !== 'Basic' && (
                            <TabPane
                                tab={
                                    <span className="report-tabs__title">
                                        <CarOutlined />
                                        Pedidos a domicilio
                                    </span>
                                }
                                key="3"
                            >
                                <HomeDeliveries
                                    allRest={false}
                                ></HomeDeliveries>
                            </TabPane>
                        )}
                    </Tabs>
                )}

                {!isAuth && (
                    <div className="all-report__container">
                        <AuthAdminForm setIsAuth={setIsAuth} />
                    </div>
                )}
            </Content>
        </Layout>
    );
};

export default Report;
