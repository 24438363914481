import React, { useEffect, useState } from 'react';
import {
    Typography,
    Divider,
    Button,
    Row,
    Col,
    Empty,
    Collapse,
    Popconfirm,
    notification,
    message,
    Spin,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { getAccessTokenApi, logout } from '../../../../api/auth';
import { getCompanyApi, getCompanyIdApi } from '../../../../api/company';
import { editOrderClientApi } from '../../../../api/order';
import { formatMoney } from '../../../../utils/general';
import useCompany from '../../../../hooks/useCompany';

export default function ShoppingCart(props) {
    const {
        orderActive,
        setreloadPage,
        setIsModalVisible,
        setOrderActiveUpdate,
        orderId,
        setIsModalVisibleCart,
    } = props;
    const { subtotal, details } = orderActive;
    const [reloadOrder, setReloadOrder] = useState(false);
    const [total, setTotal] = useState(subtotal);
    const [products, setProducts] = useState(details);
    const [confirmOrder, setConfirmOrder] = useState(true);
    const [messageActiveSpin, setMessageActiveSpin] = useState(
        'Enviando la orden...'
    );
    const [activeSpin, setActiveSpin] = useState(false);

    const { company, setCompany } = useCompany();

    const { Title, Text, Paragraph } = Typography;
    const { Panel } = Collapse;

    useEffect(() => {
        let suma = 0;

        details.forEach((element) => {
            if (element.typeUoms !== undefined) {
                suma =
                    suma +
                    (element.extraPrice + parseFloat(element.priceUoms)) *
                        element.quantity;
            } else {
                suma =
                    suma +
                    (element.extraPrice + element.price) * element.quantity;
            }
        });

        setTotal(suma);
        setReloadOrder(false);
        // setCounterItem(orderGeneral.orderLinesList.length);

        setOrderActiveUpdate({
            ...orderActive,
            subtotal: suma,
            details: details,
            counterItem: details.length,
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadOrder]);

    useEffect(() => {
        if (company === null) {
            setConfirmOrder(false);
            const token = getAccessTokenApi();
            const idComany = getCompanyIdApi();
            getCompanyApi(token, idComany)
                .then((response) => {
                    if (response?.statusCode === 401) {
                        notification['error']({
                            message: 'Usuario no autorizado',
                        });

                        setTimeout(() => {
                            logout();
                            window.location.reload();
                        }, 1500);
                        return;
                    }
                    if (response.error) {
                        return false;
                    }
                    setCompany({
                        ...company,
                        Location: response.address,
                        State: response.state,
                        Neighborhood: response.neighborhood,
                        ZipCode: response.zipCode,
                        City: response.city,
                        Telephone: response.phone,
                        CompanyImage: response.companyImage,
                        DeliveryCostKM: response.deliveryCostKM,
                        DeliveryCost: response.deliveryCost,
                        DeliveryExtraKmCost: response.deliveryExtraKmCost,
                        DeliverryMaxDistance: response.deliverryMaxDistance,
                        ServiceMaxDistance: response.serviceMaxDistance,
                        AllowMultipleActiveOrders:
                            response.allowMultipleActiveOrders,
                        Name: response.name,
                        PickUp: response.pickUp,
                        ToTable: response.toTable,
                        Delivery: response.delivery,
                        Latitude: response.latitude,
                        Longitude: response.longitude,
                        CreditCard: response.creditCard,
                        CashOnly: response.cashOnly,
                        OrderMinimumPurchase: response.orderMinimumPurchase,
                    });
                    setConfirmOrder(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company]);

    function confirm(itemId) {
        const found = details.findIndex((item) => item.itemId === itemId);
        details.splice(found, 1);
        setOrderActiveUpdate({ ...orderActive, details: details });
        setProducts(details);
        setReloadOrder(true);
        message.success('Eliminado exitosamente');
    }

    function cancel(e) {}

    function incrementItem(dish) {
        const found = details.findIndex((item) => item.itemId === dish.itemId);
        details[found].quantity = dish.quantity + 1;
        setProducts(details);
        // setOrderState(orderState);
        setReloadOrder(true);
    }

    function decrementItem(dish) {
        const found = details.findIndex((item) => item.itemId === dish.itemId);
        details[found].quantity = dish.quantity - 1;
        setProducts(details);
        setReloadOrder(true);
    }

    const createOrder = async () => {
        let updateOrder = {
            orderId: orderId,
            orderLinesList: details,
            dateUpdate: moment().unix(),
        };

        setMessageActiveSpin('Enviando la orden...');
        setActiveSpin(true);

        //payment intent id
        // updateOrder.StripePaymentMethod = stripePaymentMethod;

        let orderSend = updateOrder;

        if (orderSend.orderLinesList && orderSend.orderLinesList.length > 0) {
            orderSend.orderLinesList.forEach((orderLine) => {
                if (orderLine.measurementUnits) {
                    orderLine.measurementUnits = parseFloat(
                        orderLine.measurementUnits
                    );
                }
                orderLine.selectedOptionIds = [];
                if (orderLine.options) {
                    orderLine.options.forEach((opt) => {
                        orderLine.selectedOptionIds = [
                            ...orderLine.selectedOptionIds,
                            opt.sectionOptionId,
                        ];
                    });
                }
                orderLine.itemId = parseInt(orderLine.itemId);
            });
        }

        console.log('Orden a enviar', orderSend);
        const token = getAccessTokenApi();
        const result = await editOrderClientApi(token, orderSend);
        console.log(result);
        if (result !== undefined) {
            if (result.statusCode === 200) {
                notification['success']({
                    message: 'Orden creada exitosamente',
                });
                setActiveSpin(false);
                setIsModalVisibleCart(false);
                setIsModalVisible(false);
                setreloadPage(true);
                // history.push(`/statusOrder/${result.result.orderId}`);
            } else {
                let message = 'Ocurio un error, intentelo de nuevo';

                if (result.statusCode === 404) {
                    message = result.description;

                    setActiveSpin(false);
                    notification['error']({
                        message: message,
                    });
                } else if (result.statusCode === 405) {
                    setActiveSpin(false);
                    notification['error']({
                        message: result.description,
                    });
                    // history.push('/disable/restaurant');
                } else if (result.statusCode === 401) {
                    setActiveSpin(false);
                    notification['error']({
                        message: result.description,
                    });
                    logout();
                    setTimeout(() => {
                        window.location.href = '/login';
                    }, 1000);
                } else {
                    setActiveSpin(false);
                    notification['error']({
                        message: message,
                    });
                }
            }
        } else {
            let message = 'Ocurio un error, intentelo de mas tarde';
            setActiveSpin(false);
            setIsModalVisible(false);
            notification['error']({
                message: message,
            });
        }
    };
    return (
        <Spin spinning={activeSpin} tip={messageActiveSpin}>
            <Paragraph>Orden actualizada el: {moment().format('L')}</Paragraph>
            <Divider>Tu Orden</Divider>

            {details.length > 0 && (
                <div className="basket-form__container-add">
                    <Button
                        size="small"
                        type="primary"
                        onClick={() => setIsModalVisible(false)}
                    >
                        Agregar Producto
                    </Button>
                </div>
            )}

            <div
                className={
                    details.length > 3
                        ? 'basket-form__container-groupsItem'
                        : ''
                }
            >
                {products.map((item) => {
                    return (
                        <Row
                            className="basket-form__container-items"
                            key={item.itemIdUnique}
                            // key={item.itemId}
                        >
                            <Col span={14}>
                                <Title level={4}>{item.name}</Title>
                                <Row>
                                    <p className="gray">
                                        $
                                        {formatMoney(
                                            item.priceUoms
                                                ? parseFloat(item.priceUoms)
                                                : item.price
                                        )}
                                    </p>
                                    {item.measurementUnits !== 0 &&
                                        item.measurementUnits !== undefined && (
                                            <p className="gray">
                                                -({item.measurementUnits})
                                                {item.typeUoms}
                                            </p>
                                        )}
                                </Row>

                                {item.instructions !== '' && (
                                    <Collapse ghost>
                                        <Panel header="Instrucciones" key="1">
                                            <p>{item.instructions}</p>
                                        </Panel>
                                    </Collapse>
                                )}
                                <div className="basket-form__container-items">
                                    {item.options.map((option) => (
                                        <Row key={option.sectionOptionId}>
                                            <Col span={24}>
                                                <Text className="section-items">
                                                    <Text
                                                        strong
                                                    >{`${option.nameSection}: `}</Text>
                                                    <Text>{`${option.name}`}</Text>

                                                    {option.price && (
                                                        <Text className="gray margin-left">
                                                            $
                                                            {formatMoney(
                                                                option.price
                                                            )}
                                                        </Text>
                                                    )}
                                                </Text>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            </Col>
                            <Col span={10}>
                                <Row className="counter">
                                    <Col span={8}>
                                        <Button
                                            disabled={item.quantity === 1}
                                            onClick={() => decrementItem(item)}
                                            danger
                                        >
                                            -
                                        </Button>
                                    </Col>
                                    <Col span={8}>{item.quantity}</Col>
                                    <Col span={8}>
                                        <Button
                                            onClick={() => incrementItem(item)}
                                            danger
                                        >
                                            +
                                        </Button>
                                    </Col>
                                    <Col span={24} className="right">
                                        <Popconfirm
                                            title={`¿Estás seguro de eliminar el producto: ${item.name}?`}
                                            onConfirm={() =>
                                                confirm(item.itemId)
                                            }
                                            onCancel={cancel}
                                            okText="Si"
                                            cancelText="No"
                                        >
                                            <Button icon={<DeleteOutlined />} />
                                        </Popconfirm>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    );
                })}
            </div>

            {details.length === 0 && (
                <Empty
                    className="empty-basket"
                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                    imageStyle={{
                        height: 60,
                    }}
                    description={<span>No hay ningun producto</span>}
                >
                    <Button
                        size="small"
                        type="primary"
                        onClick={() => setIsModalVisible(false)}
                    >
                        Agregar
                    </Button>
                </Empty>
            )}

            <Row
                className="basket-form__container"
                style={{ marginBottom: 20, marginTop: 15 }}
            >
                <Col span={14} className="basket-form__container-left">
                    <Paragraph>Total de la orden</Paragraph>
                </Col>
                <Col span={10} className="basket-form__container-right">
                    <Paragraph>${formatMoney(total)}</Paragraph>
                </Col>
            </Row>

            <Button
                disabled={products.length === 0 || confirmOrder === false}
                type="primary"
                danger
                block
                onClick={createOrder}
            >
                Confirmar Pedido
            </Button>
        </Spin>
    );
}
