import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { Divider, Layout, Skeleton, notification } from 'antd';
import HeaderTop from '../../../components/General/HeaderTop';
import ListUsers from '../../../components/Admin/UsersClient/ListUsers/ListUsers';
import { getAccessTokenApi, logout } from '../../../api/auth';
import { getAllUserFiltersApi } from '../../../api/user';
import { getCompanyIdApi } from '../../../api/company';
import useAuth from '../../../hooks/useAuth';
import AdminHome from '../Perfil';

import './UsersClient.scss';

const UsersClient = () => {
    const { user } = useAuth();
    const { Content } = Layout;
    const [usersActive, setUsersActive] = useState([]);
    // const [usersInactive, setUsersInactive] = useState([]);
    const [reloadUsers, setreloadUsers] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [roleUser, setRoleUser] = useState('');
    const token = getAccessTokenApi();
    const companyId = getCompanyIdApi();

    useEffect(() => {
        const roleId =
            user[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];
        setRoleUser(roleId);
    }, [user]);

    useEffect(() => {
        if (roleUser === 'Admin') {
            setLoadingUsers(true);

            //users
            const filter = `?UserCompanyId=${companyId}&UserType=${3}`;
            getAllUserFiltersApi(token, filter).then((response) => {
                console.log(response);

                if (response?.statusCode === 401) {
                    notification['error']({
                        message: 'Usuario no autorizado.',
                    });

                    setTimeout(() => {
                        logout();
                        window.location.reload();
                    }, 1500);
                    return;
                } else {
                    if (response.statusCode === 200)
                        setUsersActive(response.result);
                    setLoadingUsers(false);
                }
            });

            setreloadUsers(false);
        }
    }, [companyId, reloadUsers, roleUser, token]);

    if (roleUser === 'Operator') {
        return (
            <>
                <Route path="/admin" component={AdminHome} />
                <Redirect to="/admin" />
            </>
        );
    }

    const onSearchByCode = (code) => {
        let filter = `?UserCompanyId=${companyId}&UserType=${3}`;
        if (code !== '') {
            filter = `?Code=${parseInt(code)}`;
        }
        getAllUserFiltersApi(token, filter).then((response) => {
            if (response?.statusCode === 401) {
                notification['error']({
                    message: 'Usuario no autorizado.',
                });

                setTimeout(() => {
                    logout();
                    window.location.reload();
                }, 1500);
                return;
            } else {
                if (response.statusCode === 200)
                    setUsersActive(response.result);
                setLoadingUsers(false);
            }
        });
    };

    return (
        <Layout className="collaborators">
            <HeaderTop title={'Clientes'} backIcon={true} backUrl="/admin" />
            {roleUser === 'Admin' && (
                <Content className="collaborators__content">
                    {!loadingUsers && (
                        <ListUsers
                            usersActive={usersActive}
                            setreloadUsers={setreloadUsers}
                            onSearchByCode={onSearchByCode}
                        />
                    )}
                    {loadingUsers && (
                        <div>
                            <div style={{ textAlign: 'right' }}>
                                <Skeleton.Button active size="large" />
                            </div>
                            <Divider />
                            <Skeleton active paragraph={{ rows: 2 }} />
                            <Divider />
                            <Skeleton active paragraph={{ rows: 2 }} />
                            <Divider />
                            <Skeleton active paragraph={{ rows: 2 }} />
                        </div>
                    )}
                </Content>
            )}
        </Layout>
    );
};

export default UsersClient;
