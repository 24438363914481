import React, { useEffect, useState } from 'react';
import {
    Form,
    Spin,
    Input,
    Tooltip,
    Button,
    Col,
    Row,
    Select,
    notification,
} from 'antd';
import {
    UserOutlined,
    MailOutlined,
    PhoneOutlined,
    QuestionCircleOutlined,
    ShopOutlined,
    EditOutlined,
} from '@ant-design/icons';
import { getAccessTokenApi } from '../../../../api/auth';
import { updateUserAdminApi } from '../../../../api/user';
import { getCompanyIdApi } from '../../../../api/company';
import Modal from '../../../../components/General/Modal';
import AddressForm from '../../../../components/Client/AddressForm';

import './EditUserForm.scss';

const EditUserForm = ({ user, setIsVisibleModal, setreloadUsers }) => {
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        console.log(user);
        setUserData([
            {
                name: ['Name'],
                value: user.name,
            },
            {
                name: ['LastName'],
                value: user.lastName,
            },
            {
                name: ['email'],
                value: user.email,
            },
            {
                name: ['userType'],
                value: user.userType,
            },
            {
                name: ['phoneNumber'],
                value: user.phoneNumber,
            },
            {
                name: ['Companies'],
                value: user.companies,
            },
            {
                name: ['Code'],
                value: user.code,
            },
            {
                name: ['Active'],
                value: user.active,
            },
            {
                name: ['Address'],
                value: user.address,
            },
            {
                name: ['ZipCode'],
                value: user.zipCode.toString(),
            },
            {
                name: ['Neighborhood'],
                value: user.neighborhood,
            },
            {
                name: ['City'],
                value: user.city,
            },
            {
                name: ['State'],
                value: user.state,
            },
        ]);
    }, [user]);

    const updateUser = async (values) => {
        console.log(values);
        const token = getAccessTokenApi();
        const companyId = getCompanyIdApi();

        let userUpdate = values;
        delete userUpdate.email;
        userUpdate['phoneNumber'] = userUpdate['phoneNumber'].toString();
        userUpdate['UserCompanyId'] = companyId;
        userUpdate['id'] = user.id;

        const result = await updateUserAdminApi(token, userUpdate);

        if (result === undefined) {
            notification['error']({
                message:
                    'Verifique su conexión a internet, si no se resuelve intente más tarde.',
            });
        } else {
            if (result.statusCode !== 200) {
                let msg = 'Ocurrio un problema, Intente mas tarde por favor.';

                if (result.statusCode === 409) {
                    msg = result.description;
                }

                notification['error']({
                    message: msg,
                });
            } else {
                //recreo correctamenta
                setIsVisibleModal(false);
                setreloadUsers(true);
                notification['success']({
                    message: 'Usuario modificado exitosamente',
                });
            }
        }

        setLoading(false);
    };

    return (
        <div className="edit-user-form">
            <EditForm
                userData={userData}
                setUserData={setUserData}
                updateUser={updateUser}
                loading={loading}
            />
        </div>
    );
};

export default EditUserForm;

function EditForm(props) {
    const { userData, setUserData, updateUser, loading } = props;
    const { Option } = Select;
    const [disableButton, setDisableButton] = useState(true);
    const [isModalVisibleAddress, setIsModalVisibleAddress] = useState(false);

    const [addressRest, setAddressRest] = useState({
        address: null,
        zipCode: null,
        neighborhood: null,
        city: null,
        state: null,
    });
    const tailLayoutButton = {
        wrapperCol: {
            offset: 0,
            span: 24,
        },
    };

    useEffect(() => {
        if (
            addressRest.neighborhood &&
            addressRest.address &&
            addressRest.zipCode &&
            addressRest.city &&
            addressRest.state
        ) {
            setUserData([
                {
                    name: ['Address'],
                    value: addressRest.address,
                },
                {
                    name: ['ZipCode'],
                    value: addressRest.zipCode.toString(),
                },
                {
                    name: ['Neighborhood'],
                    value: addressRest.neighborhood,
                },
                {
                    name: ['City'],
                    value: addressRest.city,
                },
                {
                    name: ['State'],
                    value: addressRest.state,
                },
            ]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addressRest]);

    return (
        <Spin tip="Guardando..." spinning={loading}>
            <Form
                className="form-edit"
                onFinish={updateUser}
                fields={userData}
                scrollToFirstError={true}
                onFieldsChange={(changedFields, allFields) => {
                    setUserData(allFields);
                    if (disableButton !== false) {
                        setDisableButton(false);
                    }
                }}
            >
                <Row gutter={24}>
                    <Col md={24} xs={24}>
                        <Form.Item
                            name="Name"
                            label="Nombre del usuario"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese un nombre',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Nombre"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                        <Form.Item
                            name="LastName"
                            label="Apellido del usuario"
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor ingrese un apellido',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined />}
                                placeholder="Apellido"
                            />
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item name="Code" label="Código" hasFeedback>
                    <Input placeholder="Código" disabled />
                </Form.Item>

                <Row gutter={24}>
                    <Col md={24} xs={24}>
                        <Form.Item
                            label="Correo Electrónico"
                            name="email"
                            hasFeedback
                            rules={[
                                {
                                    type: 'email',
                                    message:
                                        'El correo electrónico no es válido',
                                },
                                {
                                    required: true,
                                    message:
                                        'Por favor ingrese un correo electrónico',
                                },
                            ]}
                        >
                            <Input
                                prefix={<MailOutlined />}
                                placeholder="Email"
                                disabled
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} md={24}>
                        <Form.Item
                            name="phoneNumber"
                            label={
                                <span>
                                    Teléfono&nbsp;
                                    <Tooltip title="Tú número telefónico debe contener 10 dígitos">
                                        <QuestionCircleOutlined />
                                    </Tooltip>
                                </span>
                            }
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor ingresa tú número telefónico',
                                },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (
                                            !value ||
                                            value.toString().length === 10
                                        ) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject(
                                            'Por favor ingresa tú número telefónico a 10 dígitos'
                                        );
                                    },
                                }),
                            ]}
                        >
                            <Input
                                prefix={<PhoneOutlined />}
                                placeholder="Teléfono"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={24} xs={24}>
                        <Form.Item
                            label="Rol"
                            name="userType"
                            // hasFeedback
                            // rules={[
                            //     {
                            //         required: true,
                            //         message: 'Por favor seleccione un rol',
                            //     },
                            // ]}
                        >
                            <Select placeholder="Selecciona un rol" disabled>
                                <Option value={3}>Cliente</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item
                    className="perfil-form__container-button"
                    hidden={loading}
                    {...tailLayoutButton}
                >
                    <Button
                        icon={<EditOutlined />}
                        type="primary"
                        size="large"
                        block
                        onClick={() => setIsModalVisibleAddress(true)}
                        style={{ marginBottom: 20 }}
                        ghost
                    >
                        Agregar dirección
                    </Button>
                </Form.Item>

                <Form.Item
                    name="Address"
                    label="Calle y Número"
                    hidden={loading}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item name="ZipCode" label="Código Postal" hasFeedback>
                    <Input disabled placeholder="Código Postal" />
                </Form.Item>

                <Form.Item
                    name="Neighborhood"
                    label="Colonia"
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    name="City"
                    label="Ciudad"
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    disabled
                    name="State"
                    label="Estado"
                    hidden={loading}
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input disabled />
                </Form.Item>

                <Form.Item
                    name="Companies"
                    label="Nombre de la compañia del cliente"
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Por favor ingrese un nombre',
                            whitespace: true,
                        },
                    ]}
                >
                    <Input
                        prefix={<ShopOutlined />}
                        placeholder="Nombre de la compañia"
                    />
                </Form.Item>

                <Form.Item>
                    <Button block type="primary" htmlType="submit">
                        Actualizar Usuario
                    </Button>
                </Form.Item>
            </Form>
            <Modal
                isVisible={isModalVisibleAddress}
                setIsVisible={setIsModalVisibleAddress}
                title={'Dirección del establecimiento'}
                footer={false}
                destroyOnClose
            >
                <AddressForm
                    type="adminPerfil"
                    setAddressRest={setAddressRest}
                    setIsModalVisibleAddress={setIsModalVisibleAddress}
                    setDisableButton={setDisableButton}
                />
            </Modal>
        </Spin>
    );
}
